.navbar {
    padding: 15px 0;
    justify-content: end;
}

.menus li {
    float: left;
}
.header {
    width: 100%;
    float: left;
}
.pseudo-text-effect {
    position: relative;
    padding: 0;
    overflow: hidden;
    transition: transform 0.5s;
    display: block;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 2.4px;
    color: #9a9c9e;
}
  .pseudo-text-effect::after {
    content: attr(data-after);
    display: inline-block;
    transform: translateY(100%);
    position: absolute;
    left: 0;
    transition: inherit;
  }
  .pseudo-text-effect > span {
    display: inline-block;
    transform: translateY(0%);
    transition: inherit;
  }
  .pseudo-text-effect:hover > span {
    transform: translateY(-100%);
  }
  .pseudo-text-effect:hover::after {
    transform: translateY(0%);
  }
  
.hemburgur span {
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 1px;
    background:rgb(0 0 0 / 100%);
    transition: .5s ease-in-out;
    float: left;
    position: absolute;
}
.hemburgur {
    width: 30px;
    height: 30px;
    cursor: pointer;
    transform: rotate(0deg);
    position: relative;
    float: right;
    z-index: 1;
    right: 0;
    background: transparent;
    border: none;
    padding: 0 5px;
    outline: 0;
    margin-left: 50px;
    padding: 0;
}

.hemburgur span:nth-child(1) {
    top: 30%;
}

.hemburgur span:nth-child(2) {
    top: 12px;
    opacity: 0;
}

.hemburgur span:nth-child(3) {
    top: 24px;
}
.fixed {
    -webkit-animation: smoothScroll 1s forwards;
    animation: smoothScroll 1s forwards;
    left: 0;
    position: fixed;
    top: 0;
}
.header.fixed, .effectheader header {
    box-shadow: 0 3px 3px rgb(0 0 0 / 12%);
    width: 100%;
    z-index: 3;
    background: #fff;
    padding: 15px 0;
}
.fixed .navbar, .effectheader header {
    padding: 0;
}
@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}
.header.fixed .container:before, .header.fixed:before, .effectheader .header:before, .effectheader .container:before{
    background: rgb(0 0 0 / 30%);
}

.fixed .menus li, .effectheader header .menus li{
    padding-top: 5px;
    padding-bottom: 5px;
}
/* Submenu */
.submenu {
    background: rgb(132 134 136 / 95%);
    height: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 15%;
    transition: all .5s ease;
    visibility: hidden;
    padding: 50px 0;
}
.submenuchild {
    display: none;
    float: right;
    width: 36.5%;
}
.smenu li a {   
    display: inline-block;
    font-size: 17px;
    letter-spacing: 3px;
    line-height: 26px;
    padding: 20px 0;
    position: relative;
    color: #fff !important;
}
.toplinks li:hover .submenu {
    height: 50vh;
    opacity: 1;
    visibility: visible;
}
.toplinks li:hover .submenuchild {
    display: flex;
}

.smenu ul li, .smenu ul {
    width: 100%;
    float: left;
}
.menus .smenu ul li a {
    padding: 0;
    transition: ease all 0.5s;
}
.menus .smenu ul li a {
    display: grid;
    letter-spacing: 1px;
    line-height: initial;
}

.menus .smenu ul li a strong {font-family: 'Franklin Gothic Demi';}

.menus .smenu li {padding-left: 0;position: relative;}

.menus .smenu li:before {
    content: '';
    width: 50px;
    height: 2px;
    background: #fff;
    position: absolute;
    bottom: 0;
}
.wrapper a:before {
    content: '';
    width: 0;
    height: 1px;
    background: #fff;
    bottom: 0;
    position: absolute;
    transition: ease all 0.5s;
    left: 10%;
    right: 10%;
}
header {
    width: 100%;
    float: left;
    height: 100px;
    display: flex;
    align-items: center;
}
.hemburgur.active span:first-child {
    transform: rotate(45deg);
    top: 60%;
}
.hemburgur.active span:nth-child(3) {
    transform: rotate(-45deg);
    top: 60%;
}
#hslider{
    position: static;
}
#hslider:before {
    content: '';
    background: url(../images/banner-bg.webp);
    height: 100%;
    width: 100%;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    z-index: -1;
}
.hemburgur:before {
    content: '';
    width: 500%;
    height: 200%;
    background: #fcf4ee;
    position: absolute;
    top: -40%;
    right: -300%;
    border-radius: 30px 0 0px 30px;
}
.menuslide {
    background-color: #b88c3d;
    right: 0;
    opacity: 0;
    position: fixed;
    top: 10.4%;
    transition: all .5s ease;
    visibility: hidden;
    width: 0;
    z-index: 1;
    height: 0;
    border-radius: 0px 0px 0px 250px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menus.active .menuslide {
    opacity: 1;
    transform: scaleY(1);
    visibility: visible;
    width: 40%;
    height: 85vh;
}
.menuslide::before{
    content: '';
    background-image: url(../images/menu-bg.jpg);
    width: 400px;
    height: 400px;
    background-size: contain;
    position: absolute;
    top: 0;
    background-repeat: no-repeat;
    z-index: -1;
    left: -15%;
}
.togmenu {
    width: 100%;
    float: left;
}

.togmenu ul {
    width: 100%;
    float: left;
    text-align: center;
}

.togmenu ul li {
    width: 100%;
    padding: 15px 0 !important;
    margin: 0 !important;
}

.togmenu ul li a {
    font-size: 39px;
    line-height: 50px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #fff !important;
    font-family: 'Cinzel', serif;
    pointer-events: all;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    background-image: linear-gradient(to right,#65169d,#e42f7d 50%,#fcc336 50%,#fff 50%);
    transform: translateY(0);
    transition: transform 600ms 600ms,background-position 600ms;
}
.header.fixed .menuslide {
    top: 100%;
}
.togmenu ul li a:hover, .togmenu ul li a.active {
    background-position: 0;
}