@media all and (max-width:1740px){
    
    #hslider:before {
        height: 94vh;
    }
    body {
        font-size: 18px;
        line-height: 28px;
    }
    
    .slidecont h1 {
        font-size: 40px;
        line-height: 44px;
    }
    
    .wrap {
        margin-top: 25px;
    }
    .ripsspcont h3 {
        font-size: 16px;
    }
    
    .ripsspcont span {
        font-size: 12px;
    }
    
    .ripsspcont .wrap .btn span {
        font-size: 12px;
    }
    .banner h2 {
        font-size: 44px;
        width: 30%;
        line-height: 50px;
    }
    
    .title h3 {
        font-size: 42px;
        line-height: 50px;
    }
    
    .message h3 {
        font-size: 38px;
        line-height: 52px;
    }
    
    .vcontent h3 {
        font-size: 52px;
    }
    
    .valuebox:nth-child(1) {
        left: 17%;
    }
    
    .valuebox:nth-child(2) {
        left: 27.9%;
    }
    .valuebox:nth-child(5) {
        left: 70%;
        top: 35%;
    }
    
    .valuebox:nth-child(4) {
        left: 58.5%;
    }
    main .message h3 {
        font-size: 50px;
        line-height: 60px;
    }
    
    
    .amenitiebox:nth-child(2) {
        margin-top: -25vh;
    }
    
    .amenitiescont {
        width: 46%;
    }
    
    .neightbourbox {
        padding: 20px;
    }
    
    .belgraviacont li {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.5px;
    }
    .sapbanner h3, .belgraviacont h2 {
        font-size: 42px;
        line-height: 46px;
    }
    
}
@media all and (max-width:1620px){
    .abt_cnt_data .title h1{
        font-size: 39px;
    }
    .accordion_content {
    padding: 20px;
    }
    .banner h2 {
        font-size: 40px;
        line-height: 50px;
    }
     .message h3 {
        font-size: 36px;
        line-height: 50px;
    }
    
    .title h3 {
        font-size: 38px;
        line-height: 48px;
    }
    
    .vcontent h3 {
        font-size: 50px;
        line-height: 60px;
    }
    
    .value-circle {
        top: 20px;
    }
    .valuecont h4, .valuecont p {width: 250px;}
    
    .valuebox:nth-child(2) .valuecont h4 {
        padding-top: 16px;
    }
    
    .valuebox:nth-child(2) .valuecont {
        transform: rotate(328deg);
    }
    
    .valuebox:nth-child(4) .valuecont {
        transform: rotate(33deg);
    }
    .contact footer {
        padding-top: 0;
    }
    .contact footer {
        padding-top: 0;
    }
    
    .projectinfo {
        width: 100%;
    }
    
    .projectlogo {
        width: 90%;
    }
    
    #locations::after, #home::after, #projects:before, #residence:before {
        width: 100px;
        height: 165px;
    }
    
    #home::before, #amenities::before, #location::before, #residence::after, #ethos::after, .projectbox:nth-child(2):after, #aboutus::after, #locations::before {
        width: 265px;
        height: 100px;
    }
}
@media all and (max-width:1440px){
    .abt_cnt_data .title h1{
        font-size: 37px;
    }
    .contact footer {
        padding-top: 0;
    }
    #locations::after, #home::after, #projects:before, #residence:before {
        width: 100px;
        height: 165px;
    }
    
    #home::before, #amenities::before, #location::before, #residence::after, #ethos::after, .projectbox:nth-child(2):after, #aboutus::after, #locations::before {
        width: 265px;
        height: 100px;
    }
    
    .slidecont h1 {
        font-size: 36px;
        line-height: 40px;
    }
    
    .slideimg {
        width: 25%;
    }
    
    #hslider:before {
        height: 100vh;
    }
    
    .infobox {
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    
    #projects {
        padding-bottom: 0;
    }
    .banner h2 {
        font-size: 36px;
        line-height: 46px;
    }
    
    .message h3 {
        font-size: 32px;
        line-height: 44px;
    }
    
    .title h3 {
        font-size: 34px;
        line-height: 40px;
    }
    .vcontent h3 {
        font-size: 46px;
        line-height: 58px;
    }
    
    .vcontent {
        width: 60%;
    }
    
    .valuebox:nth-child(1) {
        top: 35%;
        left: 17%;
        transform: rotate(61deg);
    }
    
    .valuebox:nth-child(2) {
        left: 29%;
        top: 52%;
    }
    
    .valuebox:nth-child(5) {
        left: 75%;
    }
    
    .valuebox:nth-child(4) {
        left: 63%;
        top: 52%;
    }
    
    .valuebox:nth-child(3) {
        left: 46%;
        top: 58%;
    }
    .value-circle img {width: 40%;}
    
    .value-circle {top: 50px;left: 50px;}
    
    .valuebox:nth-child(1) .valuecont {
        transform: rotate(300deg);
    }
    
    .valuebox:nth-child(1) .valuecont:before {margin-left: 20%;}
    
    .valuebox:nth-child(1) .valuecont:after {
        margin-left: 20%;
    }
    
    .valuebox:nth-child(5) .valuecont {
        left: -65%;
        top: 130%;
    }
    
    .valuebox:nth-child(2) .valuecont::before {
        margin-left: 25%;
    }
    
    .valuebox:nth-child(2) .valuecont::after {
        margin-left: 25%;
    }
    
    #ethos {margin-bottom: 50px;}
    
    .core-value {
        padding-top: 40px;
    }
    .projects {
        float: left;
        width: 100%;
        margin-bottom: 50px;
    }

    .projectlogo img {
        width: 220px;
    }
    
    .projectdetail {
        margin-right: 2%;
    }
    
    .projectcont .wrap {
        width: 74%;
    }
    
    .designed h3 {
        font-size: 21px;
    }
    
    .designed span {
        font-size: 9px;
        letter-spacing: 1.5px;
    }
    
    section, .core-value, .amenities, .neightbourhood, .maps {
        padding: 60px 0;
    }
    
    .sapbanner h3, .belgraviacont h2 {
        font-size: 34px;
        width: 80%;
        line-height: 40px;
        margin-bottom: 0;
    }
    
    .belgraviacont li {
        padding: 2px;
        font-size: 15px;
        line-height: 18px;
        height: 80px;
        letter-spacing: 0;
    }
    
    .belgraviacont li:nth-child(3), .belgraviacont li:nth-child(4), .belgraviacont li:nth-child(5), .belgraviacont li:nth-child(8), .belgraviacont li:nth-child(9), .belgraviacont li:nth-child(10) {
        padding-right: 5px;
    }
    
    .belgravia ul {
        width: 100%;
        float: left;
    }
    
    main .message h3 {
        width: 100%;
        font-size: 46px;
        line-height: 56px;
    }
    
    .locationcont ul li {
        padding: 7px 0;
    }
    
    body {
        font-size: 17px;
        letter-spacing: 0.5px;
        line-height: 28px;
    }
    .neightbourbox h4 {
        font-size: 17px;
        line-height: 20px;
    }
    
    .neightbourbox {
        padding: 10px;
        padding-top: 25px;
    }
    
    .neightbourbox ul li {
        font-size: 14px;
        line-height: 20px;
    }
    .web-container {
        width: 75%;
    }
    .slider img{
        height: 84vh;
    }
    .slide span{
        line-height: 30px;
    }
    .amenitiebox:nth-child(2) {
        margin-top: -40vh;
    }
    .footerform .form ul li input {
    font-size: 15px;
}
.teamimg img {
    width: 200px;
    margin-right: 20px;
}

.teamimg h3 {
    font-size: 17px;
    line-height: 26px;
}


.teamimg h4 {
    font-size: 15px;
    line-height: 20px;
}
.rippspro {
    width: 17%;
}

.teamhead h3 {
    font-size: 24px;
    line-height: 30px;
}

.teamhead h4 {
    font-size: 13px;
    line-height: 16px;
}
.teamimg .btn {
    width: 200px;
}
.teamimg .wrap span {
    font-size: 10px;
    letter-spacing: 2px;
}

.teamimg .wrap .btn:after {
    font-size: 21px;
}
}

@media all and (max-width:1380px){
    .abt_cnt_data .title h1{
        font-size: 32px;
    }
    .title h2 {
        line-height: 40px;
        padding: 0 30px;
        letter-spacing: 1.5px;
    }
    .title {
        margin-bottom: 35px;
    }
    .countnum {
        font-size: 38px;
        line-height: 30px;
    }
    .infra_slider {
        width: 500px;
    }
    .countnum .labelcont p {
        font-size: 13px;
        letter-spacing: 1.5px;
    }
    .countnum p {
        font-size: 22px;
    }
    .App::before{
        height: 5px;
    }
    .vcontent h3 {
        font-size: 40px;
        line-height: 48px;
    }
    .ripsspcont h3 {
        font-size: 14px;
    }
    .ripsspcont span {
        font-size: 11px;
    }
    #hslider:before {
        height: 85vh;
    }
    .hemburgur.active span:first-child, .hemburgur.active span:nth-child(3) {
        top: 40%;
    }
    .togmenu ul li a {
        font-size: 36px;
        line-height: 40px;
    }
    main .message h3 {
        width: 100%;
        font-size: 40px;
        line-height: 48px;
    }
    [class^=btn-] {
        height: 50px;
    }
    
    .btn-11 span {
        font-size: 14px;
        letter-spacing: 1px;
    }
    .neightbourbox {
        height: 175px;
    }
    .ew {
        height: 220px;
    }
    .togmenu ul li {
        padding: 12px 0 !important;
    }
    .logo a {
        display: inline-block;
        width: 75%;
    }
    
    .logo a img {
        width: 100%;
    }
    
    header {
        height: 80px;
    }
    
    .header .row .col-md-4 {
        display: flex;
        align-items: center;
    }
    
    .pseudo-text-effect {
        font-size: 13px;
        letter-spacing: 2px;
    }
    
    .hemburgur:hover:after, .menus.active .hemburgur::after, .hemburgur:before {
        top: -50%;
        height: 180%;
    }
    
    .hemburgur span:nth-child(1) {
        top: 22%;
    }
    
    .hemburgur span:nth-child(3) {
        top: 20px;
    }
    .slidecont h1 {
        font-size: 34px;
        line-height: 38px;
    }
    
    .slideimg {
        width: 22%;
    }
    
    #hslider .slider {
        width: 94%;
        float: right;
    }
    
    .slide span {
        font-size: 8px;
        line-height: 20px;
    }

    .designed h3 {
        font-size: 17px;
    }
    
    .designed span {
        font-size: 8px;
        letter-spacing: 1px;
    }

.copyrights p {
    font-size: 11px;
}
.ftrbox ul li {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.5px;
}
.title h3 {
    font-size: 30px;
    line-height: 34px;
}
.value-circle img {
    width: 30%;
}

.valuebox:nth-child(1) {
    top: 35%;
    left: 24%;
    transform: rotate(65deg);
}

.valuebox:nth-child(3) {
    top: 60%;
}

.valueshape {
    width: 150px;
    height: 150px;
}

.valueshape img {
    width: 40%;
}

.valuebox:nth-child(2) {
    top: 53%;
    left: 33%;
}

.valuebox:nth-child(4) {
    left: 59%;
    top: 54%;
}

.valuebox:nth-child(5) {
    top: 37%;
    left: 69%;
}

.value-circle {
    left: 60px;
}

.valuebox:nth-child(1) .valuecont {
    transform: rotate(295deg);
    top: 135%;
    left: 60%;
}

.valuecont h4 {
    font-size: 17px;
    margin-bottom: 5px;
    margin-top: 16px;
}

.valuecont p {
    font-size: 14px;
    line-height: 18px;
    width: 200px;
}

.valuecont h4, .valuecont p {
    width: 200px;
}

.valuebox:nth-child(1) .valuecont:before {
    margin-left: 10%;
}

.valuebox:nth-child(1) .valuecont:after {
    margin-left: 10%;
}

.valuebox:nth-child(2) .valuecont {
    top: 185%;
    left: 42%;
}

.valuebox:nth-child(2) .valuecont::before, .valuebox:nth-child(2) .valuecont::after {
    margin-left: 10%;
}

.valuebox:nth-child(3) .valuecont {
    transform: rotate(360deg);
}

.valuebox:nth-child(2) .valuecont h4 {
    padding-top: 0;
}

.valuebox:nth-child(4) .valuecont {
    transform: rotate(33deg);
    top: 180%;
    left: -50%;
}

.valuebox:nth-child(4) .valuecont h4 {
    padding-top: 0;
}
.ripsspcont .wrap .btn {
    width: 100px;
    height: 35px;
}
.ripsspcont .wrap .btn span {
    font-size: 10px;
}
.prodetl .slidecont {
    margin-top: 10%;
}


}
@media all and (max-width:1280px){
    .abt_cnt_data .title h1{
        font-size: 29px;
    }

    .App {
        width: 40%;
        margin-bottom: 5%;
    }
    .infra_cnt_box:first-child {
        width: 40%;
    }
    .count {
        flex-wrap: wrap;
    }
    #hslider .slider {
        width: 100%;
    }
    
    .slider img {
        height: 88vh;
    }
    
    .menuslide {
        top: 8.8%;
    }
    
    .projectdetail {
        width: 40%;
        margin-left: 5%;
        margin-right: 0;
    }
    
    #locations::after, #home::after, #projects:before, #residence:before {
        top: 0;
    }
    #residence::before {top: 10%;}
    
    .sapbanner h3, .belgraviacont h2 {
        font-size: 30px;
        line-height: 36px;
    }
    
    .belgraviacont li {
        height: 65px;
    }
    
    .locationbanner, .amntbanner, .foundermessage, .vibanner {
        height: 60vh;
    }
    .slidecont {
        width: 30%;
    }
    .banner h2 {
        font-size: 33px;
        line-height: 42px;
    }
    .value-circle {
        top: 75px;
    }
    .value-circle {
        top: 75px;
    }
    
    .valuecont h4 {
        font-size: 19px;
        padding-top: 10px;
    }
    
    .valuecont p {
        font-size: 15px;
        line-height: 22px;
    }
    
    .projectlogo img {
        width: 200px;
    }
    
    #locations::after, #home::after, #projects:before, #residence:before {
        width: 120px;
        height: 120px;
    }
    
    .amenitiebox:nth-child(2) {
        margin-top: -25vh;
    }
    #home::before, #amenities::before, #location::before, #residence::after, #ethos::after, .projectbox:nth-child(2):after, #aboutus::after, #locations::before {
        width: 150px;
        height: 100px;
    }
    footer {
        border-radius: 150px 150px 0px 0px;
    }
    .projectbox {
        margin-bottom: 50px;
    }
}
@media all and (max-width:1040px){
    .abt_cnt_data .title h1{
        font-size: 26px;
    }
  
    .accordion_title {
        height: 50px;
    }
    .accordion_title >span:nth-child(1){
        line-height: 15px;
        font-size: 15px;
      }
    .acc_sign{
        right: 13px;
    }
    footer {
        border-radius: 150px 150px 0px 0px;
        padding: 50px 0;
    }
    
    .projectinfo ul li {
        padding-bottom: 5px;
        margin-bottom: 5px;
    }
    
    .projectstn .projectbox:nth-child(even) .projectdetail {
        margin-right: 5%;
    }
    
    .infobox {
        padding-bottom: 5px;
        margin-bottom: 5px;
    }
    .slidecont h1 {
        font-size: 28px;
        line-height: 34px;
    }
    
    .slider img {
        height: 85vh;
    }
    
    .projectlogo img {
        width: 165px;
    }
    
    .projectimg {
        width: 70%;
    }
    
    .projectdetail {
        width: 30%;
    }
    
    .infobox {
        flex-wrap: wrap;
    }
    
    .infoleft {
        width: 100%;
    }
    
    .inforight {
        width: 100%;
    }
    

    body {
        font-size: 13px;
        line-height: 20px;
    }
    
    .projectcont .wrap {
        width: 100%;
    }
    
    .btn {
        height: 40px;
        width: 150px;
    }
    
    .btn span {
        font-size: 10px;
        letter-spacing: 1px;
    }
    
    .title h3 {
        font-size: 30px;
        line-height: 40px;
    }
    
    .aboutcont {
        margin: 0;
    }
    p {
        letter-spacing: 0.3px;
    }
    .projects {
        margin: 0;
    }
    .rippspro {
        width: 22%;
    }
    .teamhead h3 {
        font-size: 19px;
        letter-spacing: 1px;
    }
    .ripssteam {
        margin: 0;
    }
    
    .teamstn:nth-child(even) {
        margin: 25px 0;
    }
    .teamimg .wrap .btn:after {
        font-size: 19px;
        line-height: 21px;
    }
    .teamhead h4 {
        font-size: 12px;
        letter-spacing: 1px;
    }
}
@media all and (max-width:850px){
    .abt_cnt_data .title h1{
        font-size: 25px;
    }
    .abt_sen{
        padding: 20px ;

    }
    .abt_cnt_data p{
        width: 100%;
    }
    .abt_sen p{
        font-size: 16px;
        line-height: 26px;
    }
    .data_pop_up_cnt .title{
        margin-bottom: 20px;
    }
    .abt_data ,
    .project_cnt_data p{
        width: 100%;
    }
    .accordion{
        padding: 25px 0;
    }
    ul.sliderPoints li:after{
        height: 6px;
        width: 6px;
    }
    .header.fixed, .effectheader header{
        padding: 10px 0;
    }
    .infobox {
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    .hemburgur:before, .hemburgur::after  {
    display: none;
    }
    .slidecont {
        width: 40%;
    }
    
    .designed h3 {
        font-size: 16px;
    }
    
    .projectlogo {
        width: 100%;
    }
    
    .projectimg {
        width: 100%;
    }
    
    .projectdetail {
        width: 100%;
        margin-top: 20px;
    }
    
    .projectbox {
        flex-wrap: wrap;
        width: 90%;
        margin: 0px 5% 60px 5%;
    }
    
    .projectstn .projectbox:nth-child(even)::after, #services::after {
        top: -70px;
    }
    
    .web-container {
        width: 90%;
    }
    
    .aboutleft {
        width: 100%;
    }
    
    .footerform .form ul {
        padding: 25px 0;
        margin-bottom: 20px;
    }
    
    .footer {
        flex-wrap: wrap;
    }
    
    .ftrbox, .ftrbox:last-child {
        width: 48%;
        margin-bottom: 20px;
    }
    
    footer {
        padding-bottom: 0;
    }
    .projectcont .wrap{
        margin-top: 25px;
    }
    .footerform .form ul {
        width: 100%;
    }
    
    .footerform .form ul li {
        width: 32%;
        margin: 0 0.5%;
    }
    .hemburgur:hover span, .menus.active .hemburgur span{
        background-color: #000;
    }
    #hslider:before {
        height: 100%;
    }
    #locations::after, #home::after, #projects:before, #residence:before {
        width: 80px;
        height: 80px;
    }
    #projects:before {
        top: 3%;
    }
    .banner h2 {
        font-size: 23px;
        line-height: 30px;
        width: 40%;
    }
    
    .banner img {
        border-radius: 150px 0 0 0;
    }
    
    section, .core-value, .amenities, .neightbourhood, .maps {
        padding: 50px 0;
    }
    
    .message h3 {
        font-size: 26px;
        line-height: 40px;
    }
    
    .message {
        width: 100%;
        margin: 0;
    }
    
    .locationbanner, .amntbanner, .foundermessage, .vibanner {
        border-radius: 100px 100px 0 0;
    }
    
    .value {
        margin: 0;
        padding: 0;
    }
    
    .valuebox {
        position: static !important;
        transform: inherit !important;
        width: 100%;
        justify-content: space-around;
    }
    
    .valueshape {width: 25%;position: relative;float: left;}
    
    .value-content {
        flex-wrap: wrap;
    }
    .valuecont h4, .valuecont p {
        width: 100%;
    }
    .valueshape img {
        position: absolute;
        left: 45%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
    
    .valuecont {
        position: static !important;
        transform: inherit !important;
        width: 70% !important;
        float: left;
        text-align: left;
    }
    
    .value-circle {
        position: static;
    }
    
    #ethos {
        margin: 0;
        padding-bottom: 0;
    }
    
    .valuebox:nth-child(1) .valuecont:after, .valuebox:nth-child(2) .valuecont::after, .valuebox:nth-child(4) .valuecont::after, .valuebox:nth-child(5) .valuecont::after {display: none;}
    
    .valuebox:nth-child(4) .valuecont::before, .valuebox:nth-child(3) .valuecont::before, .valuebox:nth-child(1) .valuecont:before, .valuebox:nth-child(2) .valuecont::before, .valuebox:nth-child(5) .valuecont::before {
        display: none;
    }
    
    .hemburgur {
        margin-left: 25px;
    }
    
    .logo a {
        width: 60%;
    }
    .menus.active .menuslide {
        width: 80%;
    }
    .desktop-show{
        display: none !important;
    }
    .mobile-show{
        display: block !important;
    }
    .projectstn {
        margin-bottom: 50px;
        margin-top: 25px;
    }

.prodetl .slidecont {
    width: 70%;
}
.belgravia {
    flex-wrap: wrap;
}

.belgraviaimg {
    width: 100%;
    margin-bottom: 50px;
}

.belgraviacont {
    width: 100%;
    text-align: center;
}

.belgraviacont h2 {
    width: 100%;
}

.sapbanner, .amntbanner, .locationbanner {background-attachment: inherit;}

.locationcont {
    width: 100%;
}

.locationimg {
    width: 100%;
    margin-bottom: 30px;
}

.location {
    flex-wrap: wrap;
}

.locationmap {
    width: 100%;
    margin: 25px 0;
}

.maps {
    flex-wrap: wrap;
    width: 80%;
    margin: 0px auto;
}

.neightbourbox {
    width: 48%;
    margin-bottom: 15px;
    height: auto;
    padding: 25px;
}

.neightbourhoodrow {
    flex-wrap: wrap;
    margin-top: 25px;
}

.progallery .slick-list {
    width: 100%;
}

.galbanner {
    width: 98%;
    margin: 0 1%;
}

.slick-slider button.slick-arrow.slick-next {
    right: 0;
}

.slick-slider button.slick-arrow.slick-prev {
    left: 0;
}
.footerform .form ul li {
    width: 100%;
    margin: 5px 1%;
}

.footerform .form ul li:last-child {
    width: 140px;
}
.progallery{
    overflow: hidden;
}
main {
    width: 100%;
    float: left;
    overflow: hidden;
}

#home::before, #amenities::before, #location::before, #residence::after, #ethos::after, .projectbox:nth-child(2):after, #aboutus::after, #locations::before, #locations::after, #home::after, #projects:before, #residence:before{
    z-index: -1;
}
main .message h3 {
    font-size: 32px;
    line-height: 40px;
}

.location {
    width: 90%;
}

.amenitiebox:nth-child(2) {
    margin: 0;
}

.amenitiesimg {
    width: 100%;
}

.amenitiebox {
    flex-wrap: wrap;
}

.amenitiescont {
    width: 80%;
    padding: 15px 0;
    text-align: center;
    margin: 0 10%;
}

.amenitiebox ul li {
    text-align: left;
}
.rippspro {
    width: 32%;
    margin: 20px 2%;
}
.valuecont h4 {
    padding-top: 0;
    margin-top: 0;
}
.value-circle img {
    width: 70%;
    margin-bottom: 25px;
}
.slick-dots li {
    height: 10px;
    width: 10px;
    margin: 0;
}
.slick-dots li.slick-active button:before {
    color: #bc8b27;
}
.slick-dots li button:before {
    font-size: 20px;
}
body {
    font-size: 16px;
    line-height: 26px;
}
.infra_cnt_box:nth-child(2) {
    width: 100%;
}

.title.text-left {
    text-align: center;
}

.contentinfra {
    text-align: center;
}

.infra_cnt_box:first-child {
    margin: 0px auto 20px;
}

.slider img{
    background-position: right;
}

.infra_cnt .wrap {
    margin: 20px auto 0 auto;
}
}
@media all and (max-width:630px){
    .abt_cnt_data .title h1 {
        font-size: 21px;
        line-height: 27px;
    }
    .react-tabs__tab-list li {
        width: 40%;
        letter-spacing: 2px;
        line-height: 40px;
        border-radius: 0;
        background: transparent;
        color: #000;
    }
    ul.react-tabs__tab-list {
        width: 80%;
        margin: 0 10%;
        margin-bottom: 25px;
    }
    .projectstn{
        margin-top: 0;
    }
    .react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected {
        border-bottom: solid 1px #000;
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-position: 0;
        background-size: 100% 100%;
    }
    .labelcont p {
        font-size: 11px;
        letter-spacing: 1px;
    }
    .App::before {
        height: 3px;
        top:0;
    }
    .slidecont h1 {
        font-size: 24px;
        line-height: 32px;
    }
    .ripssprofile {
        margin-top: 15px;
    }
    .teamimg h3 {
        font-size: 14px;
        line-height: 22px;
    }
    .teamimg h4 {
        font-size: 12px;
        line-height: 20px;
    }
    .teamimg img {
        width: 120px;
        margin-right: 10px;
    }
    .slidecont {
        width: 50%;
    }
    
    .slider img {
        border-radius: 175px 0px 0px 0px;
    }
    
    .col-md-4 {
        width: 20%;
    }
    
    .col-md-8 {
        width: 80%;
    }
    
    .logo a {
        width: 100%;
    }
    
    .container {
        width: 98%;
        max-width: 98%;
    }
    
    #hslider:before {
        height: 96%;
    }
    .menuslide {
        top: 0;
    }
    
    .menus ul:first-child {
        position: relative;
        z-index: 9;
    }
    
    .menus.active ul:first-child li .pseudo-text-effect span {
        color: #fff;
    }
    
    .menus.active {
        background: #fff;
    }

.footer {
    margin-bottom: 25px;
}
#ethos::after {
    top: 10px;
}
.banner h2 {
    font-size: 19px;
    line-height: 26px;
    width: 40%;
    margin: 0;
}
.vcontent h3 {
    font-size: 40px;
    line-height: 60px;
}
.foundermessage {
    background-position: right;
}
.vibanner, .foundermessage.amntbanner {
    background-position: center;
}
.news .infra_cnt_2 {
    position: relative;
}
.infra_cnt_2 .title h2 {
    top:0;
}
.App {
    width: 100%;
   
}
.countnum{
    margin: 0;
    padding: 5px 0;
    padding-top: 0;
    margin-top: 20px;
    font-size: 32px;
    line-height: 40px;
}
.countnum .col-12.countnum {
    padding: 0;
    margin: 0;
    line-height: initial;
}
.countnum p{
    font-size: 19px;
    line-height: 24px;
    margin-left: 0;
}
}
@media all and (max-width:480px){
    .mobile_visible{
        opacity: 1;
        visibility: visible;
       }    
    .accordion_content p {
        margin: 0;
        padding: 5px 0;
      }
    .accordion_title {
        height: 45px;
    }
    .abt_data{
        width: 100%;
    }
    .title {
        margin-bottom: 20px;
    }
    .abt_sen .title h2{
        letter-spacing: 1px;
        font-size: 11px;
        padding: 0 20px ;
    }
    .abt_sen {
        padding: 20px 0px;
    }
    .abt_sen p{
        text-align: center;
        font-size: 15px;
    }
    .data_pop_up_cnt .title{
        margin-bottom: 0;
    }
   
    .accordion_content {
        padding: 18px ;
    }
    

    ul.sliderPoints li:after{
        height: 5px;
        width: 5px;
    }
    .logo_image_size img{
        width: 25% !important;
        height: 180px ;
    }
    .the_mix_slide{
        margin: 0 10px;
        padding: 0;

    }
    .the_mix_cnt .slick-slider button.slick-arrow.slick-prev, 
    .the_mix_cnt .slick-slider button.slick-arrow.slick-next{
        display: none !important;
    }
    .lsqr_residence ul li:nth-child(6),
.lsqr_residence ul li:nth-child(3){
    border-left: none
}
    .locationimg img {
        border-bottom: solid 5px #bc8b27;
        border-radius: 90px 0 0 0;
    }
    .rippspro {
        width: 46%;
    }
    .ripssprofile{
        margin: 0;
    }
    .vcontent h3 {
        font-size: 40px;
        line-height: 60px;
    }
    
    .foundermessage {
        background-position: center top;
    }
    
    .vibanner {
        background-position: center;
    }
    
    .foundermessage.amntbanner {
        background-position: center;
    }
    
    .bannercont {
        display: grid;
        width: 100%;
        left: 0;
        text-align: center;
        top: 0;
    }
    
    .slideimg {
        width: 100%;
        float: left;
    }
    
    .slidecont {
        width: 100%;
    }
    
    .bannercont .slideimg img {
        width: 165px;
    }
    
    .slidecont h1 {
        font-size: 19px;
        line-height: 26px;
    }
    
    .sapbanner h3, .belgraviacont h2, #residence .title h3 {
        width: 100%;
        margin: 5px 0;
    }
    .sapbanner h3, .belgraviacont h2, #residence .title h3 {
        
        font-size: 24px;
        line-height: 30px;
    }
    #residence::before {
        top: 3%;
    }
    
    .title h2 {
        font-size: 12px;
    }
    
    .prodetl .slidecont {
        width: 100%;
        position: absolute;
        bottom: 10%;
    }
    
    .slideimg.prjectlogo {
        position: absolute;
        top: 5%;
    }
    .belgravia {
        margin-top: 20px;
    }
    .belgraviaimg{
        margin-bottom: 20px;
    }
    .belgraviacont li {
        width: 100% !important;
        border-right: 0 !important;
        height: auto;
        padding: 15px 0 !important;
        justify-content: center;
    }
    
    .belgraviacont li:nth-child(6), .belgraviacont li:nth-child(7), .belgraviacont li:nth-child(3), .belgraviacont li:nth-child(4), .belgraviacont li:nth-child(5), .belgraviacont li:nth-child(8), .belgraviacont li:nth-child(9), .belgraviacont li:nth-child(10) {
        padding: 0;
    }
    
    .col-md-4 {
        width: 30%;
    }
    
    .col-md-8 {
        width: 70%;
    }
    
    .header.fixed .header-right {
        top: 0;
    }
    #hslider .bannercont {
        width: 90%;
        margin-left: 9%;
    }
    .banner h2{
display: none;
    }
    .title h3, .vcontent h3 {
        font-size: 26px;
        line-height: 32px;
    }
    
   
    .message h3 {
        font-size: 21px;
        line-height: 30px;
    }
    
    .message h4 {
        font-size: 14px;
        line-height: 22px;
    }
    
    .valueshape {background: linear-gradient(14deg, #65169d, #e42f7d, #fcc336);border-radius: 40px;}
    
    .web-container {
        width: 98%;
    }
    
    .value-circle img {
        width: 80%;
        margin-bottom: 35px;
    }
    
    .valuebox {
        margin-bottom: 20px;
        justify-content: space-between;
    }
    
    .valuecont h4, .valuecont p {
        width: 100%;
    }
    
    .valuecont {
        width: 60% !important;
    }
    
    .valuebox:nth-child(even) {flex-direction: row-reverse;}
    
    .valueshape img {
        width: 50%;
    }
    footer {
        border-radius: 100px 100px 0px 0px;
    }
    #residence::before{
        opacity: 0;
    }
    .banner img {height: 250px;object-fit: cover;object-position: left;}
    .belgraviacont li:last-child {
        border-bottom: 0;
    }
    main .message h3 {
        font-size: 26px;
        line-height: 30px;
    }
    #location {
        padding-bottom: 0;
    }
    
    .slick-slider button.slick-arrow.slick-prev, .slick-slider button.slick-arrow.slick-next {
        width: 60px;
        height: 40px;
    }
    .amenities.p-0 {
        padding: 0;
    }
    section#amenities {
        padding-bottom: 0;
    }
    .galbanner img {
        border-radius: 50px;
    }
    
    .maps {
        width: 100%;
        padding: 25px 0;
    }
    
    #progallery {
        padding-bottom: 0;
    }
    
    .ftrbox ul li:first-child {
        font-size: 26px;
        line-height: 40px;
    }
#contactus .banner img {
    object-position: center;
}
#Aboutus .banner img {
    object-position: center;
}

#projects {
    overflow: hidden;
}
.projectlogo img {
    width: 120px;
}
.copyrights {
    flex-wrap: wrap;
    padding: 10px 0;
}

.copyrights p {
    width: 100%;
    text-align: center;
    line-height: 20px;
}

.ftrbox {
    width: 100%;
}
.menus.active .menuslide {
    height: 100vh;
}
.ftrbox ul li:first-child {
    margin: 0;
}
.slider img {
    border-radius: 100px 0px 0px 0px;
}
.pseudo-text-effect {
    font-size: 11px;
    letter-spacing: 1px;
}
.designed h3 {
    font-size: 13px;
    letter-spacing: 3px;
}
#residence, #projects {
    padding-top: 20px;
}
#slider.p-0.mslider {
    height: auto;
}
.neightbourbox {
    width: 100%;
}
.sliderPoints {
    gap: 20px;
    margin-top: 10px;
    font-size: 13px;
}
.banner img, .projectstn .projectbox:nth-child(odd) .projectimg img {
    border-radius: 100px 0 0 0;
}
.core-value, .amenities, .neightbourhood, .maps {
    padding: 30px 0;
}
.amenities.pb-0{
    padding: 0;
}
}
@media all and (max-width:420px){

    .react-tabs ul li{
        letter-spacing: 1px ;
        white-space: nowrap;
    }
    .data_pop_up_cnt {
    width: 85%;
    padding: 50px 15px;
    }
    .footerform .form ul li input{
        line-height: 40px;
    }
    .teamhead h3 {
        font-size: 16px;
        line-height: 20px;
    }
    .teamimg img {
        width: 100px;
        margin-right: 10px;
        margin-bottom: 0;
    }
    .teamstn:nth-child(even) {
        margin: 15px 0;
    }
    .teamimg .wrap .btn:after {
        font-size: 16px;
        line-height: 18px;
    }
    .teamhead h4 {
        font-size: 10px;
        letter-spacing: 0.5px;
    }
    .rippspro {
        width: 80%;
    }
    .slider img {
        height: auto;
    }
    .footerform .form ul{
        padding-top: 0;
    }
    .col-md-8 {
        width: 60%;
    }
    .col-md-4 {
        width: 40%;
    }
    .projectstn{
        margin-bottom: 25px;
    }
    .locationcont {
        width: 90%;
        margin-right: 10%;
    }
    .locationbanner, .amntbanner, .foundermessage, .vibanner {
        border-radius: 60px 60px 0 0;
    }
    .belgraviaimg img{
        border-radius: 60px;
    }

    .menuslide::before {
        width: 250px;
        height: 250px;
        left: -25%;
    }
    
    .prodetl .slidecont {
        width: 80%;
        margin: 0 10%;
    }
    .togmenu ul li a {
        font-size: 28px;
        line-height: 36px;
    }

    .sapbanner h3, .belgraviacont h2, #residence .title h3 {
        font-size: 21px;
        line-height: 30px;
    }
    section#amenities .message {
        margin-top: 30vh;
    }

.locations .locationbanner .message {
    margin-top: 25vh;
}
    .projectstn .projectbox:nth-child(odd) .projectimg img, .banner img {
        border-radius: 100px 0 0 0;
    }
    .projectimg img{
        border-radius: 0px 100px 0px 0px;
    }
#hslider:before {
    height: 80%;
}
.message h3 {
    font-size: 17px;
    line-height: 28px;
}
.title h3, .vcontent h3 {
    font-size: 21px;
    line-height: 32px;
}
.vcontent {
    width: 90%;
}
.valueshape {
    height: 150px;
}
.projectcont .wrap {
    margin-top: 15px;
}
.header.fixed, .effectheader header {
    padding: 5px 0;
}
.teamimg p{
    margin-top: 0px;
}
.valuecont h4 {
    font-size: 16px;
    line-height: 20px;
}

.valuecont p {
    font-size: 14px;
    line-height: 20px;
}
.message h4 {
    margin-top: 0;
}
.hemburgur span{
    height: 2px;
}
}
@media all and (max-width:375px){
    .abt_cnt_data .title h1{
        font-size: 18px;
        line-height: 25px;
    }
    .countnum {
        margin: 0;
        padding: 5px 0;
        padding-top: 0;
        margin-top: 0px;
        font-size: 26px;
        line-height: 30px;
    }
    ul.react-tabs__tab-list {
        width: 100%;
        margin: 0;
        margin-bottom: 25px;
    }
    .infra_cnt_box .title h3 {
        font-size: 19px;
        line-height: 24px;
        margin-bottom: 0;
    }
    .slidecont h1 {
        font-size: 17px;
        line-height: 18px;
    }
    .title h3, .vcontent h3 {
        font-size: 18px;
        line-height: 26px;
    }
    .valueshape {
        width: 30%;
    }
    header {
        height: 50px;
    }
}
@media all and (max-width:320px){
    .projectimg img {
        border-radius: 0px 70px 0px 0px;
    }
    .valuebox {
        flex-wrap: wrap;
    }
    .sliderPoints{
        margin: 0;
    }
    .prodetl .slidecont{
        width: 100%;
        margin: 0;
        padding: 0 10px;
    }
    .valueshape {
        width: 100%;
        border-radius: 20px;
    }
    
    .valuecont {
        width: 100% !important;
        margin-top: 20px;
        text-align: center;
    }
    
    .valueshape img {
        width: 50px;
    }
    
    .rippspro {
        width: 100%;
    }
    .valueshape {
        height: 100px;
    }
}