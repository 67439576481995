

.form, .footerform .form ul, .footer, .copyrights{
    width: 100%;
    float: left;
}
.footerform .form ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 50px 0 50px 0;
    border-bottom: solid 1.5px #fffbf847;
    margin-bottom: 50px;
}
/* color changing classes  added by me*/
.color_white{
color: #fff !important;
}
.color_black{
color: #000 !important;
}

.footerform .form ul li {
    width: 28%;
    margin: 0px 1%;
    background: #fff;
    color: #000;
    border-radius: 30px;
}
.copyrights {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: solid 1.5px #fffbf847;
    padding: 0;
}

.copyrights p {
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 50px;
    letter-spacing: 0.1px;
}
.copyrights p a{
    text-decoration: none;
}
.copyrights p:hover a {
    color: #000;
}
.call {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.call a {
    color: #fff;
    text-decoration: none;
}
.footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
}
.ftrbox:last-child {
    width: auto;
}

.ftrbox ul li {
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.7px;
}

.ftrbox ul li:first-child {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 12px;
    font-size: 34px;
    line-height: 60px;
    font-weight: 300;
}
.ftrbox ul li a {
    text-decoration: none;
    display: flex;
    align-items: center;
}
.ftrbox ul li a:hover{
    color: #000;
}

footer {
    width: 100%;
    float: left;
    background: #6d6e71;
    padding: 80px 0 0 0;
    border-radius: 350px 350px 0px 0px;
}
.footerform .form ul li input {
    border: none;
    width: 100%;
    float: left;
    color: #000;
    font-size: 17px;
    line-height: 50px;
    letter-spacing: 0.5px;
    text-indent: 15px;
    background-color: initial;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.footerform .form ul li input::placeholder {
    color: rgb(255 255 255 / 70%);
}
.footerform .form ul li:last-child input {
    padding: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
}
.footerform .form ul li:last-child {
    margin-top: 40px;
    background: linear-gradient(14deg, #65169d, #e42f7d, #fcc336);
    width: 180px;
    overflow: hidden;
    border: none;
}
.footerform .form ul li input::placeholder{
    color: #939598;
}
.footerform .form ul li:last-child span {
    font-weight: 500;
}
.footerform .form ul li:last-child:hover span {
    background: transparent;
}
.ftrbox ul li a i {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background: #fffbf7;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    transition: ease all 0.5s;
}
.ftrbox:last-child ul li {
    margin-bottom: 6px;
    display: flex;
    align-items: center;
}
.ftrbox:last-child ul li a:hover i {
    background: linear-gradient(14deg, #65169d, #e42f7d, #fcc336);
    color: #fff !important;
}

.ftrbox:last-child ul li a:hover {
    color: #000;
}