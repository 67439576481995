@media all and (max-width: 1740px) {
  .infra_nmbr_box .countnum span {
    font-size: 38px;
  }
  .infra_nmbr_box .countnum h4,
  .infra_nmbr_box .countnum {
    font-size: 35px;
  }
  .infra_nmbr_box .countnum p {
    font-size: 14px;
  }
  .infra_cnt_box .title h1 {
    font-size: 34px;
    line-height: 40px;
  }
  .htag_c h2,
  .footerform .form title h2 {
    font-size: 42px;
    line-height: 50px;
  }
}
@media all and (max-width: 1620px) {
  .infra_cnt_box .title h1 {
    font-size: 31px;
    line-height: 38px;
    padding-bottom: 5px;
  }
  .htag_c h2,
  .footerform .form title h2 {
    font-size: 38px;
    line-height: 48px;
  }
  .infra_cnt_box .title h3 {
    font-size: 43px;
    padding-bottom: 5px;
  }
  .infra_cnt_2 .title p {
    padding-bottom: 5px;
  }
}
@media all and (max-width: 1440px) {
  .designed h2 {
    font-size: 21px;
  }
  .infra_cnt_box .title h1 {
    font-size: 29px;
    line-height: 34px;
  }
  .htag_c h2,
  .footerform .form title h2 {
    font-size: 34px;
    line-height: 44px;
  }
  .infra_cnt_box .title h3 {
    font-size: 36px;
    line-height: 39px;
  }
  .infra_cnt_2 .title p {
    font-size: 17px;
    line-height: 25px;
  }
  .infra_nmbr_box .countnum span {
    font-size: 32px;
    line-height: 34px;
  }
  .infra_nmbr_box .countnum h4,
  .infra_nmbr_box .countnum {
    font-size: 32px;
    line-height: 31px;
  }
  .countnum::before {
    width: 50%;
    height: 5px;
  }
}
@media all and (max-width: 1380px) {
  .designed h2 {
    font-size: 17px;
  }
  .infra_cnt_box .title h1 {
    font-size: 27px;
    line-height: 34px;
  }
  .htag_c h2,
  .footerform .form title h2 {
    font-size: 30px;
    line-height: 38px;
  }
  .Htag h1 {
    letter-spacing: 1.5px;
    line-height: 40px;
    padding: 0 30px;
  }
  .infra_cnt_box .title h3 {
    font-size: 32px;
    line-height: 35px;
  }
  .infra_cnt_2 .title p {
    font-size: 15px;
    line-height: 22px;
  }
  .infra_slider .slick-dots li button:before {
    font-size: 32px;
  }
  .infra_nmbr_box .countnum span {
    font-size: 28px;
    line-height: 29px;
  }
  .infra_nmbr_box .countnum h4,
  .infra_nmbr_box .countnum {
    font-size: 28px;
    line-height: 27px;
  }
  .infra_nmbr_box .countnum p {
    font-size: 11px;
    line-height: 11px;
  }
  .infra_number {
    border-radius: 160px 160px 0 0;
  }
  .countnum::before {
    width: 45%;
    height: 3px;
  }
}
@media all and (max-width: 1280px) {
  .infra_cnt_2,
  .infra_cnt {
    width: 90%;
  }
}
@media all and (max-width: 1160px) {
  .htag_c h2,
  .footerform .form title h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .infra_cnt_box .title h1 {
    font-size: 24px;
    line-height: 30px;
  }
  .f_start .slidecont {
    width: 40%;
  }
  .infra_cnt_2,
  .infra_cnt {
    width: 95%;
  }
  #projects {
    margin-bottom: 20px;
  }
  .infra_nmbr_box {
    width: 27%;
  }
}
@media all and (max-width: 1080px) {
  .slider .slick-dots {
    bottom: 0;
  }
  .infra_nmbr_box .countnum span {
    font-size: 25px;
    line-height: 25px;
  }
  .infra_nmbr_box .countnum h4,
  .infra_nmbr_box .countnum {
    font-size: 24px;
    line-height: 23px;
  }
  .infra_slider .slick-dots {
    bottom: -66px;
  }
}
@media all and (max-width: 786px) {
  .infra_cnt_box .title h1 {
    font-size: 27px;
    line-height: 30px;
  }
  .slider .slick-dots {
    bottom: 1vh;
  }
  .slick-dots li.slick-active button:before {
    color: #fff;
  }
  .infra_cnt_2,
  .infra_cnt {
    width: 100%;
  }
  .infra_cnt_box .title h3 {
    font-size: 26px;
  }
  .infra_slider {
    width: 80%;
  }
  .infra_nmbr_box .countnum span {
    font-size: 19px;
  }
  .infra_nmbr_box .countnum h4,
  .infra_nmbr_box .countnum {
    font-size: 18px;
    line-height: 23px;
  }
  .infra_number {
    height: 39vh;
    border-radius: 120px 120px 0 0;
  }
  .infra_nmbr_box {
    width: 26%;
  }
}
@media all and (max-width: 630px) {
  .htag_c h2,
  .footerform .form title h2 {
    font-size: 26px;
    line-height: 34px;
    padding-bottom: 20px;
  }
  .infra_slide h4 {
    font-size: 19px;
    width: 100%;
    line-height: 24px;
    padding: 0 20px;
}
  .infra_cnt_box .title h3 {
    font-size: 26px;
    line-height: 26px;
    margin-bottom: 5px;
  }
  .infra_cnt_2 .title p {
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
  }
  .infra_slide p {
    padding: 0 !important;
  }
  .Infra_n_cnt {
    flex-direction: column;
    gap: 40px;
  }
  .infra_number {
    height: auto;
    padding: 60px;
    background-attachment: inherit;
    background-position: center;
  }
  .infra_nmbr_box .countnum span {
    font-size: 24px;
  }
  .infra_nmbr_box .countnum h4,
  .infra_nmbr_box .countnum {
    font-size: 24px;
  }
  .infra_nmbr_box {
    width: 40%;
    margin: 0 auto;
  }
  .title h3 {
    font-size: 26px;
    line-height: 34px;
  }
  .infra_cnt_2 .title p {
    font-size: 17px;
    line-height: 24px;
  }
}
@media all and (max-width: 480px) {

    .Htag h1{
        font-size: 12px;
    }
    .designed h2 {
        font-size: 13px;
        letter-spacing: 3px;
    }
    .infra_cnt_box .title h1 {
        font-size: 24px;
        line-height: 29px;
    }
  .infra_nmbr_box .countnum p {
    text-align: center;
  }
  .projectbox .projectimg .btn {
    padding: 0 5px;
  }
  .infra_nmbr_box .countnum {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .infra_nmbr_box .countnum p,
  .infra_nmbr_box .countnum h4,
  .infra_nmbr_box .countnum,
  .infra_nmbr_box .countnum span {
    width: 100%;
    float: left;
  }
  .projectbox {
    padding-top: 20px;
  }
  .projectbox:first-child {
    padding-top: 0px;
  }
  body {
    font-size: 15px;
  }
  .projectinfo ul li {
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 17px;
    line-height: 28px;
  }
  .mobile_show {
    display: block;
  }
  .desktop_show {
    display: none;
  }
  .infra_cnt_2 .title p {
    padding: 0;
  }
  .infra_cnt_3 {
    margin-top: 40px;
  }
  .infra_slider .slick-dots {
    bottom: -20px;
  }
  .infra_slider .slick-dots li button:before {
    font-size: 26px;
  }
  .infra_nmbr_box {
    width: 65%;
  }
  .infra_slider .slick-dots li {
    width: 12px;
    height: 12px;
  }
  .countnum::before {
    left: 20%;
    width: 60%;
    top: -8%;
  }
  .infra_cnt {
    flex-direction: column;
    width: 100%;
  }
  .infra_slider .slick-dots li button {
    height: 9px;
    width: 9px;
  }
  .slick-dots li button {
    margin: 3px;
  }
  .infra_cnt_box:first-child img {
    width: 85%;
    padding-top: 30px;
    margin: 0 auto;
    justify-content: center;
    display: flex;
  }
  .infra_cnt_box:first-child,
  .infra_cnt_box:nth-child(2) {
    width: 100%;
    margin: 0 auto;
    align-items: center;
  }
  .infra_cnt_box:nth-child(2) h2,
  .infra_cnt_box:nth-child(2) h3 {
    width: auto;
  }
  .infra_cnt_box,
  .title.text-left {
    text-align: center;
  }
  .infra_cnt_box:first-child img {
    width: 90%;
    margin: 20px 5%;
    padding: 0;
    margin-top: 0;
  }
  .contentinfra h3 {
    margin-bottom: 0;
  }
  .f_start .slidecont {
    width: 100%;
  }
  .f_start {
    margin-top: 9 0px;
    padding-left: 0;
    margin-left: 0 !important;
    width: 100% !important;
    justify-content: center;
    float: none;
  }
  .f_start h1 {
    font-size: 25px;
    line-height: 29px;
    text-align: center;
  }
  .p_top {
    margin-top: 4vh;
  }
  .btn span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0;
    line-height: 20px;
    padding-top: 3px;
  }
  .infra_nmbr_box {
    width: 50%;
    margin: 0 auto;
  }
  .Infra_n_cnt {
    gap: 30px;
  }
  .infra_slider {
    height: auto;
    width: 93%;
    padding: 35px 0;
  }
  .infra_number {
    border-radius: 100px 100px 0 0;
  }
  .infra_nmbr_box .countnum span {
    font-size: 22px;
    line-height: 30px;
  }
  .infra_nmbr_box .countnum h4,
  .infra_nmbr_box .countnum {
    font-size: 19px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 2px;
  }
}
@media all and (max-width: 375px) {
   
    .Htag h1 {
        font-size: 10px;
        letter-spacing: 1px;
        padding: 0 20px;
    }
    .infra_cnt_box .title h1 {
        font-size: 19px;
        line-height: 26px;
    }
  .infra_cnt_2 .title p {
    padding: 0;
  }
  .infra_slider .slick-dots {
    bottom: -10px;
  }
  .infra_slider .slick-dots li button:before {
    font-size: 25px;
  }
  .infra_nmbr_box {
    width: 70%;
  }
  .countnum:before {
    left: 24%;
    top: -5px;
    width: 50%;
  }
  .infra_slider .slick-dots li {
    width: 15px;
    height: 15px;
  }
  .f_start h1 {
    font-size: 23px;
    line-height: 25px;
  }
}
@media all and (max-width: 320px) {
  .infra_nmbr_box {
    width: 75%;
  }
  .infra_slider .slick-dots {
    bottom: -12px;
  }
  .f_start {
    font-size: 21px;
  }
}
@media all and (max-width: 310px) {
  .infra_nmbr_box {
    width: 85%;
  }
}
