body {
    font-weight: normal;
    font-size: 15px;
    line-height: 36px;
    letter-spacing: 1px;
    color: #000;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    outline: 0;
    font-family: 'Gotham';
    font-size: 20px;
    line-height: 32px;
}
ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
img{
    max-width: 100%;
}
*, a{
    outline: 0;
    text-decoration: none;
}
h1, h2, h3, h4, h5, h6{
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
}
.banner, .footerform, .services, #services, .downloadenquire ul li a, .first-stn, .servicebox, .servicestn, .serviceimg, .slider, .slide, section, .aboutus, .projectstn, .projectbox{
    position:relative;
}
.projects, .tabstn, .react-tabs, .neightbourhoodrow, .amenitiebox, .amenitiesstn, .belgravia, .prodetl .slideimg, .approch, .vmsion, .founder, .footerform, .services, #services, .projenquire .wrap, .enquireform ul, .first-stn, .effectheader, .appstnrow, .approchbanner, .servicestn, .projectbox, .slider, .slide, section, .aboutus, .title, .aboutcont, .projectstn{
    width: 100%;
    float: left;
}

section, .core-value, .amenities, .neightbourhood, .maps{
    padding: 80px 0;
}

.title, .vmsion {
    text-align: center;
}
.maps, .servicestn, .projectbox, .aboutcolumn, .approchbanner, .appstnrow, .homeslider, .residancebox, .belgravia, .location, .amenitiesstn, .amenitiebox, .neightbourhoodrow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.animated {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: bounce;
    animation-name: bounce;
}

@-webkit-keyframes bounce {

    0%,
    100% {
        -webkit-transform: translateY(0)
    }

    50% {
        -webkit-transform: translateY(-20px)
    }
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0)
    }

    50% {
        transform: translateY(-20px)
    }
}

.mobile-show, .slider .slick-arrow {
    display: none  !important;
}

.desktop-show {
    display: block !important;
}

/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
}

.slick-list:focus {
    outline: none;
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

[dir='rtl'] .slick-slide {
    float: right;
}

.slick-slide.slick-loading img {
    display: none;
}

.slick-slide.dragging img {
    pointer-events: none;
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-loading .slick-slide {
    visibility: hidden;
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
    display: none;
}
.slick-slider button.slick-arrow.slick-next {
    right: 75px;
    transform: rotate(180deg);
}

.slick-slider button.slick-arrow.slick-prev {
    left: 75px;
}

.slick-slider button.slick-arrow.slick-next:before {
    transform: translate(-50%, -50%) rotate(180deg);
    top: 54%;
}
.btnfill {
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    text-decoration: none;
    padding: 0 40px;
    line-height: 50px;
    border-radius: 50px;
    letter-spacing: 1.2px;
    position: relative;
}
.button-2 {
    color: #fff;
    border: 1px solid #b69a52;
    background-image: linear-gradient(30deg, #b69a52 50%, transparent 50%);
    background-size: 500px;
    background-repeat: no-repeat;
    background-position: 0%;
    transition: 500ms ease-in-out;
}
  .button-2:hover {
    background-position: 100%;
    color: #b69a52;
  }
  
  
@-webkit-keyframes criss-cross-left {
    0% {
      left: -20px;
    }
    50% {
      left: 50%;
      width: 20px;
      height: 20px;
    }
    100% {
      left: 50%;
      width: 375px;
      height: 375px;
    }
  }
  
  @keyframes criss-cross-left {
    0% {
      left: -20px;
    }
    50% {
      left: 50%;
      width: 20px;
      height: 20px;
    }
    100% {
      left: 50%;
      width: 375px;
      height: 375px;
    }
  }
  @-webkit-keyframes criss-cross-right {
    0% {
      right: -20px;
    }
    50% {
      right: 50%;
      width: 20px;
      height: 20px;
    }
    100% {
      right: 50%;
      width: 375px;
      height: 375px;
    }
  }
  @keyframes criss-cross-right {
    0% {
      right: -20px;
    }
    50% {
      right: 50%;
      width: 20px;
      height: 20px;
    }
    100% {
      right: 50%;
      width: 375px;
      height: 375px;
    }
  }
  .btn-11 {
    position: relative;
    color: #858585;
  }
  .btn-11:before, .btn-11:after {
    position: absolute;
    top: 50%;
    content: "";
    width: 20px;
    height: 20px;
    background-color: #606264;
    border-radius: 50%;
  }
  .btn-11:before {
    left: -20px;
    transform: translate(-50%, -50%) scale(1.5);
}
  .btn-11:after {
    right: -20px;
    transform: translate(50%, -50%);
  }

.aboutcont{
    margin-top: 15px;
    text-align: center;
}
  .btn-11:hover {
    color: #606264;
  }
  .btn-11:hover:before {
    -webkit-animation: criss-cross-left 0.8s both;
            animation: criss-cross-left 0.8s both;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
  }
  .btn-11:hover:after {
    -webkit-animation: criss-cross-right 0.8s both;
            animation: criss-cross-right 0.8s both;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
  }

  [class^=btn-] {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 60px;
    max-width: 320px;
    text-transform: capitalize;
    border: 1px solid currentColor;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 50px;
    background-color: transparent;
}
.btn-11 span {
    color: #000;
    position: relative;
    z-index: 1;
    transition: ease all 1s;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2.4px;
}
.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}
.animated {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}
.animated {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite
}

@-webkit-keyframes bounce {

    0%,
    100% {
-webkit-transform: translateY(0)
    }

    50% {
-webkit-transform: translateY(-8px)
    }
}

@keyframes bounce {

    0%,
    100% {
transform: translateY(0)
    }

    50% {
transform: translateY(-8px)
    }
}
  a {
    color: inherit;
    text-decoration: none;
}

.approchbanner h3, .slide h2 {
    position: absolute;
    top: 30%;
    right: 10%;
    font-size: 48px;
    line-height: 50px;
    font-weight: normal;
    margin: 0;
    padding-bottom: 15px;
    display: grid;
}

.projectdetail{
    width: 35%;
    margin-left: 10%;
    margin-right: 3%;
}

.aboutright {
    width: 38%;
}

.img {
    width: 100%;
    float: left;
    position: relative;
}

.img:before {
    content: '';
    background: #848688;
    position: absolute;
    width: 65%;
    height: 100%;
    right: 0;
    z-index: -1;
}

.img img {
    width: 90%;
    padding: 50px 0;
}
.title h2 {
    background: #e4e4e4;
    border-radius: 25px;
    display: inline-block;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 2px;
    padding: 0 40px;
    text-transform: uppercase;  
    line-height: 50px;
}
.title, .react-tabs__tab-list{
    margin-bottom: 50px;
}
.title h3 {
    font-size: 48px;
    line-height: 50px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 300;
    margin: 0;
}
p {
    letter-spacing: 0.6px;
}
.projectimg {
    width: 50%;
    position: relative;
}

.projectlogo {
    margin-bottom: 25px;
    border-bottom: solid 1px #b2b2b2;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.projectlogo img {
    width: 250px;
}
.projectinfo ul li {
    position: relative;
    letter-spacing: 0.3px;
    display: flex;
    align-items: center;
    padding-bottom:10px;
    margin-bottom:10px;
    font-weight: 500;
    line-height: 24px;
}

.projectinfo ul li:before {
    content: '';
    width: 25px;
    height: 1px;
    position: absolute;
    bottom: 0;
    background: #939598;
}
.projectinfo ul li span {
    width: 25%;
    color: #939598;
}
.projectinfo ul li:last-child:before {
    background: transparent;
}
.projectbox {
    margin-bottom: 100px;
}

.projectstn .projectbox:nth-child(odd), .amenitiebox:nth-child(2) {
    flex-direction: row-reverse;
}

.projectstn .projectbox:nth-child(even) .projectdetail {
    margin-left: 3%;
    margin-right: 10%;
}
.projectimg img {
    border-radius: 0px 150px 0px 0px;
    width: 100%;
}
.projectstn .projectbox:nth-child(odd) .projectimg img {
    border-radius: 150px 0px 0px 0px;
}
.projectinfo {
    width: 85%;
}

.projectbox:last-child {
    margin-bottom: 0;
}
.projectcont .wrap, .servicestn {
    margin-top: 30px;
}
/* About us */

/* Projects */
.projectscroll img {
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
    width: 100%;
}
.projectscont {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 50%;
    max-height: 120px;
    position: absolute;
    text-align: center;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    width: 70%;
    z-index: 8;
}
.projectscont h1 {
    color: #fff;
    float: left;
    font-size: 30px;
    font-style: normal;
    letter-spacing: 1.5px;
    line-height: 40px;
    margin: 0;
    text-transform: uppercase;
    width: 60%;
}
button.proarrow {
    background: transparent;
    border: none;
    bottom: 2%;
    position: absolute;
    right: 2%;
}
.proarrow .downarrow a {
    padding: 0 5px;
    text-decoration: none;
}
.downarrow span {
    background: hsla(0,0%,100%,.7);
    bottom: 10px;
    cursor: pointer;
    height: 120px;
    left: 10%;
    opacity: 1;
    position: absolute;
    transition-delay: 1.5s;
    width: 2px;
}

.residance .proarrow .downarrow span, .enquire .downarrow span {
    background: rgba(0,0,0,.5);
}
.animated {
    -webkit-animation-duration: 1.4s;
    animation-duration: 1.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}
.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}
.animated {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}
.details h4, .residancecont h4 {
    color: #fff;
    font-size: 38px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: 'Franklin Gothic Book';
}
.details {
    float: left;
    text-align: center;
    width: 100%;
}
.details p, .details ul {
    align-items: center;
    color: #fff;
    display: inline-block;
    flex-wrap: wrap;
    font-weight: 300;
    justify-content: center;
    margin: 0;
    padding: 0;
}
.details p span, .details ul li {
    border-right: 1px solid #ededed;
    color: #fff;
    display: inline-block;
    font-size: 19px;
    font-weight: 300;
    letter-spacing: 0.8px;
    line-height: 15px;
    margin: 10px 10px 10px 0;
    padding-right: 10px;
    text-transform: uppercase;
}
.residancebox {
    height: 90vh;
    margin: 10vh auto 0;
    width: 55%;
}
.residanceimg {
    width: 55%;
}
.residancecont {
    width: 40%;
}
.residancecont h4 {
    color: #000;
    font-family: 'Franklin Gothic Demi Cond';
    line-height: inherit;
}
.projectscroll .residanceimg img {
    height: auto;
}
.residancecont ul li {
    font-size: 19px;
    line-height: 30px;
    padding: 10px 0;
    position: relative;
}
.residancecont ul li:before {
    background: #4a4848;
    bottom: 0;
    content: "";
    height: 1px;
    position: absolute;
    width: 30px;
}
.sectioncont {
    position: absolute;
    top: 50%;
    width: 70%;
    transform: translate(-50%,-50%);
    left: 50%;
    text-align: center;
}
.top-title {
    color: #fff;
    margin: 0 auto 80px;
    max-width: 500px;
    text-align: center;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.top-title h2 {
    display: inline-block;
    font-size: 45px;
    font-weight: 400;
    letter-spacing: 2.2px;
    line-height: 60px;
    margin: 0;
    text-transform: uppercase;
}
.top-title.fixd h2 {
    border-color: #fff;
    color: #fff;
}
.btnenquire a:hover, .downloadenquire ul li a:hover, .slidebtn:hover span {
    background: #939598;
    color: #fff;
    text-decoration: none;
}
.fp-watermark {
    opacity: 0;
    display: none;
}
.effectfooter, .fp-viewing-0 .effectheader{
    opacity: 0;
}
.effectheader {
    box-shadow: 2px 0 3px rgb(85 85 85 / 50%);
    opacity: 1;
    position: absolute;
    top: 0;
    transition: all .5s ease;
    visibility: visible;
    width: 100%;
    z-index: 1;
    transition-delay: 1s;
    transition-duration: 0.5s;
}
.effectheader header {
    background: hsla(0,0%,100%,.7);
}

.effectheader header .menus ul li a {
    color: #000;
}
/* Gallery */
.gallerycontainer {
    margin: 0 auto;
    overflow: hidden;
    width: 90%;
}
.gallery.projectgal {
    margin: 0;
}
.projectgal .slick-slider .slick-list {
    margin: 0 auto;
    overflow: visible;
    width: 70%;
}
.galbanner {
    float: left;
    position: relative;
    width: 99%;
}
.galbanner:before {
    background: rgba(0,0,0,.5);
    content: "";
    height: 100%;
    position: absolute;
    transition: all .5s ease;
    width: 100%;
    transition: ease all 0.5s;
    border-radius: 100px;
}
.galbanner img {
    border-radius: 100px;
    width: 100%;
}
.slick-slide.slick-active.slick-current .galbanner:before{
    opacity: 0;
}
.projectscroll .projectgal img {
    height: auto;
    width: 100%;
}
.enquireform {
    align-items: center;
    background: rgba(0,0,0,.95);
    display: flex;
    height: 0;
    justify-content: center;
    left: 50%;
    opacity: 0;
    position: fixed;
    top: 50%;
    transform: translate(-50%,-50%);
    transition: all .5s ease;
    transition-duration: .5s;
    visibility: hidden;
    width: 100%;
    z-index: 9;
}
.enquireform.active {
    height: 101vh;
    opacity: 1;
    visibility: visible;
}
.enquirecontainer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.header.fixed .enquireform.active {
    transform: translate(-50%,-5%);
}

.enquirecross {
    align-items: center;
    background: #383838;
    border-radius: 100%;
    color: #fff;
    cursor: pointer;
    display: flex;
    font-size: 19px;
    height: 75px;
    justify-content: center;
    position: absolute;
    right: 5%;
    top: 5%;
    width: 75px;
}
.enquireform h2 {
    color: #ededed;
    float: left;
    font-size: 36px;
    letter-spacing: 2px;
    margin-bottom: 35px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}
.enquireform ul {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}
.menus .enquireform ul li {
    float: left;
    margin: 5px 0;
    width: 32%;
}
.enquireform ul li input[type=email], .enquireform ul li input[type=tel], .enquireform ul li input[type=text] {
    background: transparent;
    border: 1px solid #403f3f;
    color: #fff;
    height: 50px;
    text-indent: 15px;
    width: 100%;
    letter-spacing: 1px;
}
.projectdetailsbtn {
    position: fixed;
    top: 50%;
    right: 0;
    display: flex;
    justify-content: flex-end;
    width: 100px;
    z-index: 10;
}

.projenquire {width: 100%;}

.projenquire button.btn-11 {
    border: none;
    background: #fff;
    border-radius: 0;
    padding: 0 80px;
    transform: rotate(90deg);
    width: 100%;
    text-transform: uppercase;
    font-family: 'Franklin Gothic Demi';
    letter-spacing: 4px;
    font-size: 15px;
}

.enquireform button.btn-11 span {
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.enquireform button.btn-11 {
    background: #383838;
}
.plogo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plogo img {
    height: auto;
    width: auto;
}
.logosectioncont {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.sectioncont h1 {
    margin: 0;
    font-size: 48px;
    line-height: 55px;
    color: #fff;
    letter-spacing: 2px;
}

.top-title.backcolor {
    color: #000;
}
.details p span:last-child, .details ul li:last-child{
    border: none;
}

.locationview {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
}

.locationview button.btn-11 {
    padding: 0 80px;
    white-space: nowrap;
    color: #fff;
    max-width: 200px;
}

.locationview button.btn-11 span {
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.locationview button.btn-11 em {
    font-style: inherit;
}
.locationview button.btn-11:hover {
    border: solid 1px #606264;
}
.gallery.projectgal {
    margin-top: 25vh;
}
.downloadenquire {
    margin-top: 10vh;
}

.enquire .enquirecontainer {
    width: 60%;
    margin-top: 12vh;
    margin-left: 20%;
}

.enquire .enquirecontainer ul {
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
}

.enquire .enquirecontainer ul li {
    width: 46%;
    float: left;
    margin: 2%;
}

.enquire .enquirecontainer ul li input {
    width: 100%;
    float: left;
    text-indent: 15px;
    border-radius: 50px;
    border: solid 1px #c3c3c3;
    height: 50px;
    font-size: 19px;
    line-height: 30px;
}
.enquire .enquirecontainer .btn-11 span {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 19px;
    font-weight: 600;
    color: #fff;
}

.enquire .enquirecontainer button.btn-11 {
    max-width: 200px;
    background-color: #000;
}
.section.footer {
    align-items: center !important;
    margin-top: 25vh;
}
.pfooter {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.pfooter footer {
    height: 57.2vh;
    display: flex;
    align-items: flex-end;
}
.pfooter .footer {
    margin-bottom: 150px;
}
.serviceimg img {height: auto;}
/* Icons */
.projectstn .projectbox:nth-child(even)::after, #services::after {
    transform: inherit;
    left: inherit;
    right: -25px;
}

.App {
    width: 20%;
    color: #fff;
    position: relative;
}
.whitebtn .btn-3::after {
    bottom: -1px;
}


.countnum {
    font-size: 46px;
    line-height: 40px;
    display: flex;
    margin: 0;
    flex-wrap: wrap;
    font-family: 'Playfair Display', serif;
}

.countnum p {
    margin: 0;
    text-transform: uppercase;
    font-size: 26px;
    margin-left: 5px;
    width: 100%;
}

.labelcont p {
    font-size: 15px;
    line-height: 27px;
    width: 100%;
    float: left;
    margin-top: 10px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: normal;
    white-space: nowrap;
    margin: 0;
}


p.space {
    padding-left: 10px;
}

.jujhargroup {
    border-bottom: dotted 1px #b1abab;
    padding-bottom: 80px;
}
#aboutjujhar .aboutus:before {
    opacity: 0;
    content: '';
    display: none;
}

.visitbtn .btn-11 {
    max-width: fit-content;
    padding: 0 30px;
}
.section.profooter {
    justify-content: flex-end;
}
.proheader {
    position: absolute;
    width: 100%;
    z-index: 1;
    top: 0;
    padding: 20px 0;
}
.whitelogo {
    width: 90px;
}
.navigation {
    position: absolute;
    background: #848688;
    width: 100%;
    top: 12.5vh;
}

.pronav {
    width: 100%;
    float: left;
}

.pronav ul {
    display: flex;
    align-items: center;
    justify-content: center;
}
.pronav ul li {
    padding: 5px 50px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 4px;
}
#fp-nav.fp-right {
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0 !important;
    top: 14.5%;
    background: #848688;
    transition-duration: 0.5s;
    transition-delay: 1s;
}

#fp-nav.fp-right ul {
    display: flex;
}

#fp-nav.fp-right ul li {
    padding: 0px 50px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 4px;
    width: auto;
    height: auto;
    margin: 0;
}

#fp-nav.fp-right ul li a span {
    overflow: visible;
    clip: auto;
    width: auto;
    background: transparent;
    height: auto;
    position: static;
}
#fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span{
    margin: 0 !important;
}
#fp-nav.fp-right ul li .active {
    color: #000;
}
#fp-nav ul li a, .fp-slidesNav ul li a{
    color: #fff;
}
#fp-nav.fp-right ul li:last-child, #fp-nav.fp-right ul li:first-child, #fp-nav.fp-right ul li:nth-child(7) {
    display: none;
}

body.fp-scroll-mac.fp-viewing-1 #fp-nav.fp-right, body.fp-scroll-mac.fp-viewing-1 .effectheader {
    opacity: 0;
}
/* .menus li a.active {
    color: #eb364a;
} */
.count {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-around;
}
.title.text-left {
    text-align: left;
}
.wrap {
    margin-top: 50px;
}