/* home updates css */
.projectinfo p {
  font-size: 17px;
  letter-spacing: 1.2px;
  line-height: 24px;
  font-weight: 400;
}
.lcn_bg {
  background-image: url("../images/ls_wraps\ copy.webp");
}
.f_start .slidecont {
  width: 30%;
}
.f_start {
  justify-content: flex-start;
  color: #fff;
  padding-left: 10%;
  margin-top: 10vh;
}
.infra_cnt_box img {
  border-radius: 80px 80px 0 0;
}
.the_mix_cnt {
  width: 85%;
  z-index: 2;
}
.the_mix_slide {
  padding: 12px;
}
.logo_image_size img {
  width: 8% !important;
  height: 180px;
}

.infra_cnt_2,
.infra_cnt {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  gap: 2%;
}

.infra_cnt_box:first-child {
  width: 30%;
}
.infra_cnt_box:nth-child(2) {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.infra_slider {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  width: 700px;
  padding: 70px 0;
  border: solid 1px #ededed;
}
.infra_cnt_2 .title h2 {
    top: 20%;
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    background-image: linear-gradient(90deg, #ad2f8e 5%, #e33a72 50%, #fb9031 100%);
    color: #fff;
}
.infra_slide {
  width: 100%;
  float: left;
}
.infra_slide p {
  font-size: 18px;
  width: 70%;
  margin: 0 auto;
}
.infra_slider_cnt {
  width: 100%;
  float: left;
}
.infra_slider_cnt .slick-slider button.slick-arrow.slick-prev,
.infra_slider_cnt .slick-slider button.slick-arrow.slick-next {
  display: none !important;
}
.infra_slider .slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
.infra_slider .slick-dots li button {
  font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
    margin: 0;
  }
  .infra_slider .slick-dots li.slick-active button:before{
    opacity: 1;
  }
.infra_slider .slick-dots li button:before{
  font-family: slick;
  font-size: 35px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    content: '•';
    text-align: center;
    color: #000; 
}
.slick-dots li.slick-active button:before {
  color: #f7f0f1;
}
.infra_slider .slick-dots {
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
  position: absolute;
  bottom: -60px;
}

.infra_cnt_box .title p {
  font-size: 20px;
  line-height: 30px;
  font-family: "Gotham";
  font-weight: 400;
}
.infra_cnt_2 .title p {
  font-size: 19px;
  line-height: 30px;
  font-family: "Gotham";
  text-align: center;
  letter-spacing: 0.7px;
  padding-bottom: 10px;
}
.infra_number {
  width: 100%;
  float: left;
  background: url(../images/Infra_number_bg.webp);
  height: 55vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 210px 210px 0px 0;
  background-position: bottom;
}

.Infra_n_cnt {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  width: 100%;
  flex-direction: row;
}
.infra_nmbr_box .countnum span {
  font-size: 40px;
}
.infra_nmbr_box .countnum p {
  font-size: 20px;
  line-height: 19px;
  text-align: left;
  font-weight: 400;
  text-transform: uppercase;
}
.infra_nmbr_box .countnum h4,
.infra_nmbr_box .countnum {
  font-size: 39px;
  line-height: 40px;
  color: #ffffff;
  font-weight: bold;
  font-family: "Gotham";
  text-transform: uppercase;
  text-align: left;
}

.infra_nmbr_box {
  position: relative;
  width: 23%;
}
.App::before {
  content: "";
  width: 60%;
  height: 7px;
  background: linear-gradient(90deg, #ad2f8e 5%, #e33a72 50%, #fb9031 100%);
  position: absolute;
  left: 0;
  z-index: 2;
  top: -10px;
}


.contact .title h3,
.contact .ftrbox,
.contact .copyrights{
    color: #000 !important;
}

.contact .copyrights{
    border-top: solid 1px #c8c0b9;

}   
.contact .footerform .form ul{
    border-bottom: solid 1px #c8c0b9;
}

.contact .btn span{
    background-color: transparent !important;
    color: #fff;
}

.slick-dots li button{
  padding: 0;
  margin: 5px;
}
.slick-dots li button:before {
  content: "";
}
.slider .slick-dots {
  bottom: 1vh;
}
.slider .slick-dots li {
  float: right;
}
.mobile_show{
  display: none;
}
.center{
  justify-content: center;
}
