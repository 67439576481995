  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');
.bannercont {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  top: 8%;
}
.container {
  width: 93%;
  max-width: 93%;
  margin: 0px auto;
}
.slidecont {
  width: 25%;
}
.slidecont h1 {
  margin: 0;
  font-size: 49px;
  font-weight: 300;
  line-height: 50px;
  font-family: "Cinzel", serif;
}
.slide span {
  font-size: 10px;
  letter-spacing: 0.8px;
  line-height: 36px;
  float: left;
  color: #a3a3a3;
}
.projectcont ul li strong {
  font-weight: 500;
}
.aboutcolumn {
  width: 50%;
  margin: 0px auto;
  text-align: center;
}
.btn {
  border-radius: 40px;
  box-sizing: border-box;
  display: block;
  height: 50px;
  letter-spacing: 1px;
  margin: 0 auto;
  padding: 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  width: 180px;
  z-index: 2;
  float: left;
  background: linear-gradient(14deg, #65169d, #e42f7d, #fcc336);
  outline: 0;
  overflow: hidden;
  transition: ease all 0.5s;
}
.btn:before {
  content: "";
  background: linear-gradient(14deg, #65169d, #e42f7d, #fcc336);
  position: absolute;
  width: 0;
  height: 100%;
  z-index: 2;
  left: 0;
  right: 0;
  transition: ease all 0.5s;
}
.react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected {
  background: linear-gradient(14deg, #65169d, #e42f7d, #fcc336);
}
.btn:hover:before {
  width: 100%;
}
.btn:hover span {
  color: #fff;
}

.btn span {
  align-items: center;
  background: #fff;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #000;
  position: relative;
  z-index: 1;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.4px;
}

.btn:hover span {
  background-color: transparent;
  z-index: 2;
}
.btn:hover {
  border: hidden;
  background: transparent;
  border: solid 1px #cbc3c3;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
.fillbtn {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fillbtn .btn span {
  background: transparent;
  color: #fff;
}

.fillbtn .btn {
  border: none;
}
#locations::after,
#home::after,
#projects:before,
#residence:before {
  content: "";
  background-image: url(../images/shape-1.gif);
  width: 120px;
  height: 220px;
  position: absolute;
  top: 5%;
  left: 2%;
  background-repeat: no-repeat;
  background-size: contain;
}
#home::before,
#amenities::before,
#location::before,
#residence::after,
#ethos::after,
.projectbox:nth-child(2):after,
#aboutus::after,
#locations::before {
  content: "";
  background-image: url(../images/shape-2.gif);
  width: 313px;
  height: 111px;
  position: absolute;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}
#ethos::after {
  top: 5%;
}
#locations::after,
#locations::after,
#home::before,
#amenities::before,
#location::before,
#aboutus::after,
#locations::before {
  background-image: url(../images/shape-2.gif);
  transform: scaleX(-1);
  left: -25px;
  right: inherit;
}
.banner img {
  border-radius: 260px 0px 0px 0px;
}
.slideimg.prjectlogo img {
  border-radius: unset !important;
}
.banner h2 {
  position: absolute;
  top: 40%;
  left: 5%;
  font-size: 57px;
  width: 36%;
  line-height: 60px;
  text-transform: uppercase;
  font-weight: 300;
  display: grid;
}
.locationbanner,
.amntbanner,
.foundermessage,
.vibanner {
  width: 100%;
  float: left;
  background: url(../images/quote.webp);
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 260px 260px 0px 0;
}
.sapbanner {
  background-image: url(../images/probanner01.jpg);
}

.web-container {
  width: 66%;
  margin: 0px auto;
}

.message {
  text-align: center;
  width: 80%;
  margin: 0px auto;
}

.message h3 {
  font-size: 44px;
  line-height: 60px;
  text-transform: uppercase;
  font-weight: 300;
}
.message h4 {
  margin: 0;
  margin-top: 50px;
  display: grid;
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
  text-transform: uppercase;
}

.message h4 span {
  font-weight: 400;
}
.aboutleft {
  width: 75%;
  margin: 0px auto;
}
.vibanner {
  background-image: url(../images/vm-banner.webp);
  border-radius: 260px 260px 0px 0;
}
.approch,
.vcontent {
  margin-bottom: 50px;
}
.approch,
.core-value {
  float: left;
  width: 100%;
}

.vcontent {
  color: #fff;
  width: 50%;
  margin: 50px auto;
}
.vcontent h3 {
  font-size: 58px;
  font-weight: 300;
  line-height: 70px;
}
.valueshape {
  width: 200px;
  height: 200px;
  background-image: url(../images/shape.webp);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.valuebox {
  width: 20%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.value-content {
  display: flex;
}
.valuecont h4 {
  font-size: 22px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 600;
}

.valuecont p {
  font-size: 16px;
  margin: 0;
  line-height: 20px;
  letter-spacing: 0;
  font-weight: 400;
}
.banner.rightpos h2 {
  left: inherit;
  color: #fff;
}
.prodetl {
  flex-wrap: wrap;
  text-align: center;
  height: 100%;
  top: 0;
}

.prodetl .slideimg img {
  width: 15%;
  height: auto;
}

.bannercont .slideimg img {
  height: auto;
}
.prodetl .slidecont {
  width: 45%;
  color: #fff;
  margin-top: 18%;
}
.slider.project {
  width: 96%;
  float: right;
}
.belgravia {
  margin-top: 50px;
}

.belgraviaimg {
  width: 45%;
}

.belgraviacont {
  width: 50%;
}

.sapbanner h3,
.belgraviacont h2 {
  font-size: 48px;
  line-height: 50px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 300;
}

.belgraviaimg img {
  width: 100%;
  border-radius: 100px;
}

.belgraviacont li {
  border-bottom: solid 1px #bc8b27;
  letter-spacing: 0.5px;
  float: left;
  width: 100%;
  padding: 10px 0;
  line-height: 24px;
  font-size: 18px;
  margin: 0;
  height: 100px;
  display: flex;
  align-items: center;
  padding-right: 50px;
}

.belgraviacont li:nth-child(3),
.belgraviacont li:nth-child(4),
.belgraviacont li:nth-child(5),
.belgraviacont li:nth-child(8),
.belgraviacont li:nth-child(9),
.belgraviacont li:nth-child(10) {
  width: 33.33%;
  padding-right: 15px;
  border-right: solid 1px #bc8b27;
  padding-left: 15px;
  display: flex;
  align-items: center;
}

.belgraviacont li:nth-child(5) {
  border-right: none;
}

.belgraviacont li:nth-child(3) {
  padding-left: 0;
}
.belgraviacont li:nth-child(6),
.belgraviacont li:nth-child(7) {
  width: 50%;
  padding-left: 20px;
  padding-right: 20px;
}

.belgraviacont li:nth-child(6) {
  border-right: solid 1px #bc8b27;
  padding-left: 0;
}
.belgraviacont li:last-child {
  border-right: 0;
}
.sapbanner h3 {
  font-size: 48px;
}

.location {
  flex-direction: row-reverse;
  width: 80%;
  float: right;
}

.locationimg {
  width: 65%;
}
.locationcont {
  width: 30%;
}

.locationimg img {
  width: 100%;
  border-bottom: solid 10px #bc8b27;
  border-radius: 120px 0 0 0;
}
.locationcont ul li {
  padding: 10px 0;
  border-bottom: solid 1px #bc8b27;
  font-weight: 500;
  text-transform: uppercase;
  width: 80%;
}
.locationcont ul li:last-child {
  border: none;
}
.amntbanner {
  background-image: url(../images/amenitie-banner.jpg);
}
.amenitiesstn {
  flex-wrap: wrap;
}
.amenitiesimg {
  width: 50%;
}
.amenitiescont {
  width: 42%;
}
.amenitiebox {
  align-items: self-start;
}
.amenitiebox:nth-child(2) {
  align-items: flex-end;
  margin-top: -32vh;
}
.amenitiebox ul {
  width: 92%;
  float: right;
}

.amenitiebox ul li {
  padding: 7px 0;
  border-bottom: solid 1px #bc8b27;
  text-transform: uppercase;
}

.amenitiebox ul li:last-child {
  border: none;
}

.amenitiebox:first-child img {
  border-radius: 0px 150px 0px 0px;
  border-bottom: solid 10px #bc8b27;
}
.amenitiebox:nth-child(2) .amenitiesimg {
  display: flex;
  justify-content: flex-end;
}

.amenitiebox:nth-child(2) .amenitiesimg img {
  width: 90%;
}
.locationbanner {
  background-image: url(../images/location-banner.jpg);
}
.locationbanner .message {
  color: #fff;
}

.neightbourbox {
  background: #bc8b27;
  width: 24%;
  padding: 25px;
  border-radius: 60px;
  height: 200px;
  text-align: center;
  color: #fff;
}
.neightbourbox h4 {
  font-size: 19px;
  letter-spacing: 0;
  font-weight: 600;
  display: grid;
}

.neightbourbox ul li {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.neightbourbox ul {
  width: 100%;
}
.neightbourbox h4 span {
  text-transform: uppercase;
}

.locationmap {
  width: 49%;
}

.Gallerybox {
  width: 100%;
  float: left;
}

.lg-react-element {
  width: 100%;
  float: left;
  display: flex;
}

.lg-react-element img {
  width: 100%;
  border-radius: 50px;
}

.progallery .slick-list {
  width: 65%;
  margin: 0px auto;
  overflow: visible;
}

.slick-slider button.slick-arrow.slick-prev,
.slick-slider button.slick-arrow.slick-next {
  background-image: url("../images/arrow.webp");
  background-color: transparent;
  border: none;
  font-size: 0;
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 45%;
  z-index: 1;
}

.slider .slick-dots {
  bottom: 0px;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  width: 35%;
  z-index: 1;
}
.slider .slick-dots li {
  margin: 0 2%;
  width: 27%;
  float: left;
}
.slider .slick-dots li button {
  background: #000;
  border-radius: 0;
  height: 2px;
  width: 100%;
  font-size: 0;
  outline: 0;
  border: none;
}

.slider .slick-dots li.slick-active button {
  background: #ea3549;
}

.btn_bg .btn-11,
.locations .btn-11 {
  background: #ba8c34;
  border-color: #c9c9c9;
}
.btn_bg .btn-11 span,
.locations .btn-11 span {
  color: #fff;
}

.hemburgur:after,
.menus.active .hemburgur::after {
  width: 0;
  background: linear-gradient(14deg, #65169d, #e42f7d, #fcc336);
  transition: ease all 0.5s;
  content: "";
  position: absolute;
  top: -40%;
  height: 200%;
  border-radius: 30px 0 0px 30px;
  right: -310%;
}

.hemburgur:hover:after,
.menus.active .hemburgur::after {
  width: 500%;
}

.hemburgur:hover span,
.menus.active .hemburgur span {
  background: #fff;
  z-index: 2;
}
main h3,
main h2 {
  font-family: "Cinzel", serif;
}

#residence .title h3 {
  width: 80%;
  margin: 15px auto;
}
main .message h3 {
  font-size: 62px;
  line-height: 70px;
}
#residence:before,
#home::after,
#locations::after {
  background-image: url(../images/proshape-01.gif);
}
#residence::after,
#amenities::before {
  bottom: 0;
  top: 50%;
  background-image: url(../images/proshape-02.gif);
  right: -25px;
  left: inherit;
}

#locations::before,
#location::before,
#home::before {
  top: 10%;
  background-image: url(../images/proshape-02.gif);
}
#amenities::before {
  transform: scaleX(1);
}
#home::before {
  top: 0;
}
#locations::after,
#home::after {
  left: inherit;
  right: 5%;
}
#locations::after {
  top: 50%;
}
#locations::before {
  top: 65%;
}
.value {
  width: 100%;
  float: left;
  text-align: center;
  position: relative;
  padding-bottom: 40vh;
  margin-left: -40px;
}
.valuebox:nth-child(1) {
  transform: rotate(64deg);
  position: absolute;
  top: 34%;
  left: 19%;
}
.valuebox:nth-child(2) {
  top: 50.4%;
  left: 28.9%;
  transform: rotate(32deg);
  position: absolute;
}
.valuebox:nth-child(3) {
  top: 57%;
  left: 43%;
  position: absolute;
}
.valuebox:nth-child(4) {
  top: 51%;
  left: 57%;
  transform: rotate(328deg);
  position: absolute;
}
.valuebox:nth-child(5) {
  top: 34.8%;
  left: 67%;
  transform: rotate(295deg);
  position: absolute;
}
.value-circle {
  position: relative;
  left: 38px;
  top: 60px;
}

.valuebox:nth-child(1) .valuecont {
  position: absolute;
  transform: rotate(296deg);
  top: 143%;
  left: 40%;
  width: 100%;
}
.valuebox:nth-child(2) .valuecont {
  position: absolute;
  left: 20%;
  top: 170%;
  width: 100%;
  transform: rotate(329deg);
}
.valuebox:nth-child(3) .valuecont {
  position: absolute;
  left: 0%;
  top: 120%;
  width: 100%;
  transform: rotate(361deg);
}
.valuebox:nth-child(4) .valuecont {
  position: absolute;
  left: -25%;
  top: 170%;
  width: 100%;
  transform: rotate(32deg);
}
.valuebox:nth-child(5) .valuecont {
  position: absolute;
  left: -40%;
  top: 140%;
  width: 100%;
  transform: rotate(65deg);
}
.valuebox:nth-child(4) .valuecont::before,
.valuebox:nth-child(3) .valuecont::before,
.valuebox:nth-child(1) .valuecont:before,
.valuebox:nth-child(2) .valuecont::before,
.valuebox:nth-child(5) .valuecont::before {
  content: "";
  width: 1px;
  height: 120px;
  background: #464953;
  position: absolute;
  top: -100%;
}
.valuebox:nth-child(1) .valuecont:after,
.valuebox:nth-child(2) .valuecont::after,
.valuebox:nth-child(4) .valuecont::after,
.valuebox:nth-child(5) .valuecont::after {
  content: "";
  width: 20px;
  position: absolute;
  height: 1px;
  background: #464953;
  top: -100%;
}
.valuebox:nth-child(2) .valuecont::after,
.valuebox:nth-child(4) .valuecont::after {
  transform: rotate(330deg);
  top: -104%;
}

.valuebox:nth-child(2) .valuecont h4,
.valuebox:nth-child(4) .valuecont h4 {
  padding-top: 10px;
}
.valuebox:nth-child(3) .valuecont::before {
  height: 30px;
  top: -45%;
}
.valuebox:nth-child(4) .valuecont::after {
  transform: rotate(32deg);
  right: 49%;
}
.valuebox:nth-child(5) .valuecont::after {
  right: 50%;
}
.footerform .form ul li:last-child:hover {
  background: transparent;
}
.slide01.slide.aos-init.aos-animate .banner,
.belgravia.aos-init.aos-animate .belgraviaimg img {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  visibility: visible;
  transform: skewX(0);
}
.slide01.slide .banner,
.belgraviaimg img {
  transition: ease all 0.8s;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  visibility: hidden;
  transition: 1s ease-out;
  transition-delay: 0.2s;
}
.slider img {
  width: 100%;
  border-radius: 260px 0px 0px 0px;
  height: 86vh;
  object-fit: cover;
}
#slider {
  height: 85vh;
}
.location.aos-init.aos-animate .locationimg img {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  visibility: visible;
  transform: skewX(0);
}
.locationimg img {
  transition: ease all 0.8s;
  clip-path: polygon(100% 100%, 100% 100%, 100% 100%, 100% 100%);
  visibility: hidden;
  transition: 1s ease-out;
  transition-delay: 0.2s;
}
main .title h2 {
  font-family: "Montserrat", sans-serif;
}

.contact footer,
.contact .footerform .form ul li:last-child span {
  background: #fff;
}

.contact .footerform .form ul li input[type="text"],
.contact .footerform .form ul li input[type="email"],
.contact .footerform .form ul li input[type="tel"] {
  border: solid 1px #c8c0b9;
  border-radius: 30px;
}
.appcont p {
  margin-bottom: 5px;
}
#hslider .slidecont h1 {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}
.designed {
  display: grid;
  border-left: solid 1px #ededed;
  padding-left: 10px;
  text-align: center;
}

.designed span {
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  line-height: 16px;
  font-weight: 500;
  color: #939598;
}

.designed h3 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 5px;
  line-height: 24px;
  margin: 0;
  padding: 0;
}

.overview h4 {
  font-weight: 600;
  font-size: 17px;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 30px;
}
.overview ul li {
  padding-bottom: 5px;
  margin-bottom: 5px;
  position: relative;
  font-size: 18px;
  letter-spacing: 0.2px;
}

.overview ul li:before {
  content: "";
  width: 25px;
  position: absolute;
  height: 1px;
  background: #000;
  bottom: 0;
}

.overview ul li:last-child:before {
  background: #fff;
}
.infobox {
  display: flex;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
  justify-content: space-between;
}

.infoleft {
  width: 25%;
  color: #939598;
  text-transform: uppercase;
}

.inforight {
  width: 72%;
}

.projectinfo .inforight ul li:last-child {
  padding: 0;
  margin: 0;
}

.infobox:before {
  content: "";
  width: 25px;
  height: 1px;
  position: absolute;
  bottom: 0;
  background: #939598;
}

.inforight ul li:before {
  content: "";
  background: #000;
}

.infobox:last-child:before {
  content: "";
  background: #fff;
}
.projectcont {
  width: 100%;
  float: left;
}

.projectcont .wrap {
  width: 76%;
  float: right;
  margin-top: 0;
}

.header.fixed .header-right {
  position: relative;
  top: 5px;
}
.menus {
  display: flex;
  align-items: center;
}
.menus ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.desktop-show {
  display: block;
}
.mobile-show {
  display: none;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: black;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: pink;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: pink;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: pink;
}
.ripssteam {
  margin-top: 25px;
  width: 100%;
  float: left;
}

.teamstn {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.teamimg {
  width: 100%;
  float: left;
}

.teamcont {
  width: 73%;
}

.teamimg h3 {
  font-weight: 700;
  font-size: 24px;
  margin: 0;
  line-height: 30px;
}
.teamimg img {
  width: 250px;
  float: left;
  margin-right: 35px;
  margin-bottom: 50px;
}
.teamimg p {
  margin-bottom: 5px;
}

.teamimg h4 {
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 26px;
  color: #857e7e;
}
.teamstn:nth-child(even) {
  flex-direction: row-reverse;
  margin: 50px 0;
}
.pseudo-text-effect:hover {
  color: #ac8834;
}

.menus li:first-child {
  margin-right: 50px;
}

.projenquire .wrap .btn {
  background: #b89035;
}

.projenquire .wrap .btn span {
  background: transparent;
  color: #000;
  font-weight: 500;
}

.ripssprofile {
  float: left;
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.rippspro {
  width: 15%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 1%;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  border-radius: 50px 50px 0px 0px;
}

.ripsspimg img,
.ripsspimg {
  width: 100%;
  border-radius: 50px 50px 0px 0px;
}

.ripsspcont {
  width: 100%;
  margin: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.ripsspcont h3 {
  font-size: 19px;
  font-weight: 600;
  line-height: 26px;
  margin: 0;
  text-transform: uppercase;
}

.ripsspcont span {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 20px;
  width: 100%;
  float: left;
}
.ripsspcont .wrap {
  margin-top: 25px;
  float: left;
  width: 100%;
  display: flex;
}
.ripsspcont .wrap .btn span {
  font-size: 13px;
  letter-spacing: 1px;
}

.ripsspcont .wrap .btn {
  width: 125px;
  height: 40px;
}
.teamhead h3 {
  display: grid;
  font-size: 30px;
  letter-spacing: 1.5px;
  line-height: 40px;
  width: 100%;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
}

.teamhead h4 {
  font-size: 14px;
  letter-spacing: 2px;
  color: #848484;
  margin: 0;
  line-height: 20px;
  text-transform: uppercase;
}

.teamhead {
  position: relative;
  padding-left: 15px;
}

.teamhead:before {
  content: "";
  width: 5px;
  height: 100%;
  position: absolute;
  background: linear-gradient(14deg, #65169d, #e42f7d, #fcc336);
  left: 0;
  border-radius: 20px;
}

#teaminfo {
  border-top: solid 1px #ededed;
}
.teamimg .btn {
  width: 250px;
  position: relative;
}
.teamimg .wrap .btn:after {
  content: "\27F5";
  font-size: 24px;
  position: absolute;
  color: #000;
  top: 20%;
  left: 17%;
  transform: translateX(-50%);
  z-index: 1;
  line-height: 24px;
  transition: ease all 0.5s;
}
.teamimg .wrap:hover .btn:after {
  color: #fff;
  z-index: 2;
}
.teamimg .wrap {
  width: 100%;
  float: left;
  margin-top: 25px;
}
.sliderPoints {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 15px;
  width: 100%;
  float: left;
}
/* Deepak code */
.themix_banner {
  z-index: 1;
  position: relative;
  margin-top: 10px;
}
.ew {
  width: 31%;
  height: 280px;
  text-align: center;
  color: #fff;
}

/* -----lord Squre */
.lsqr_residence ul li:nth-child(2),
.lsqr_residence ul li:nth-child(3) {
  width: 50%;
}
.lsqr_residence ul li:nth-child(7) {
  width: 100%;
}
.lsqr_residence ul li:nth-child(4),
.lsqr_residence ul li:nth-child(5),
.lsqr_residence ul li:nth-child(6) {
  width: 33.33%;
}
.lsqr_residence ul li:nth-child(10) {
  border-right: none;
}
.lsqr_residence ul li:nth-child(7),
.lsqr_residence ul li:nth-child(11),
.lsqr_residence ul li:nth-child(1),
.lsqr_residence ul li:nth-child(2) {
  padding-left: 15px;
}
.lsqr_residence ul li:nth-child(6),
.lsqr_residence ul li:nth-child(3) {
  border-right: none;
  border-left: solid 1px #bc8b27;
  padding-left: 5%;
}
.lsqr_residence ul li:nth-child(3),
.lsqr_residence ul li:nth-child(7) {
  padding-right: 8%;
}
.themix_banner img {
  z-index: 10;
}
/* home updates css */
.projectinfo p {
  font-size: 17px;
  letter-spacing: 1.2px;
  line-height: 24px;
  font-weight: 400;
}
.lcn_bg {
  background-image: url("../images/ls_wraps\ copy.webp");
}
.f_start .slidecont {
  width: 30%;
}
.f_start {
  justify-content: flex-start;
  color: #fff;
  padding-left: 10%;
  margin-top: 10vh;
}
.infra_cnt_box img {
  border-radius: 80px 80px 0 0;
}
.the_mix_cnt {
  width: 85%;
  z-index: 2;
}
.the_mix_slide {
  padding: 12px;
}
.logo_image_size img {
  height: 180px;
  margin-top: 4%;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}
.tabstn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-tabs__tab-list li {
  margin: 0 10px;
  background: #535355;
  color: #fff;
  border-radius: 25px;
  line-height: 55px;
  font-size: 13px;
  letter-spacing: 4px;
  cursor: pointer;
  outline: 0;
  text-transform: uppercase;
  width: 220px;
  text-align: center;
  display: inline-block;
}
ul.react-tabs__tab-list {
  text-align: center;
  width: 100%;
  float: left;
}
.lg-react-element span {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 10px;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
}
.infra_slide h4 {
  font-size: 24px;
  font-weight: bold;
  padding: 0 30px;
  width: 80%;
  margin: 0px auto;
  margin-bottom: 10px;
}
.sliderPoints li {
  display: flex;
  line-height: 20px;
}
.sliderPoints li:last-child {
  border: none;
  padding-right: 0;
}
.infra_cnt_box .title h1 {
  font-size: 40px;
  line-height: 47px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 300;
}
.Htag h1 {
  background: #e4e4e4;
  border-radius: 25px;
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 2px;
  padding: 0 40px;
  text-transform: uppercase;
  line-height: 50px;
  margin: 0;
  white-space: nowrap;
}
.designed h2 {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 5px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  font-weight: 600;
  text-transform: uppercase;
}
.htag_c h2,
.footerform .form title h2 {
  font-size: 48px;
  line-height: 50px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 300;
  background: none;
  border-radius: none;
  margin: inherit;
  padding: 0;
}
.popupShow {
  display: flex !important;
}
.data_pop_up {
  position: fixed;
  height: 100%;
  width: 100vw;
  z-index: 10;
  top: 0;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgb(0 0 0 / 56%);
}
.data_pop_up_cnt {
  background-color: #fff;
  width: 80%;
  height: 80%;
  padding: 5%;
  overflow-y: scroll;
  position: relative;
}
.cross {
  position: absolute;
  top: 50px;
  right: 50px;
  cursor: pointer;
}
.cross:hover {
  color: red;
}
.projectstn p {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
.accord_data {
  margin: 40px auto;
  position: relative;
}
.pt-60 {
  padding-top: 60px;
}
.accordion {
    padding: 40px 0;
    display: flex;
    gap: 10px;
    flex-direction: column;
}
.accordion_item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.accordion_title:hover{
  background-color: #faede3; 
}
.c_active{
  background-color: #f7e1d0 !important;
  transition: all 0.3s ease-in;
}
.accordion_title >span{
  vertical-align: middle;
  line-height: 18px;
  font-size: 18px;
}
.accordion_title >span:nth-child(2){
  line-height: 25px;
  font-size: 25px;
  margin-left: auto;

}
.accordion_title {
  background: #fcf4ee;
  border-radius: 35px;
  vertical-align: middle;
  height: 55px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 18px;
font-family: 'Montserrat';
}

.accordion_content{
  min-height: 0;
}
.accordion .pannel{
  max-height: 0;
  overflow: hidden;
  transition:  max-height 0.5s ease-out;
  width: 100%
}
.accordion .active .pannel{
max-height: 2000px;
transition: max-height 0.5s ease-in;
}


.acc_sign:hover {
  color: #e94a71;
}
.acc_sign_active {
  color: #e94a71;
}
.accordion_content {
  width: 100%;
  text-align: left;
  margin: 0 auto;
  padding: 25px;
}
.project_cnt_data p {
  margin: 0 auto;
  text-align: center;
}
.abt_cnt_data,
.abt_data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.abt_data {
  text-align: center;
  gap: 10px;
}
.abt_cnt_data p {
  width: 75%;
  text-align: center;
}
.abt_cnt_data .title,
.abt_sen .title {
  margin-bottom: 10px;
}
.abt_sen .title h2 {
  white-space: nowrap;
  background-image: linear-gradient(90deg,#ad2f8e 5%,#e33a72 50%,#fb9031);
  color: #fff;
}
.abt_sen:first-child {
  border-bottom: solid 1px #ededed;
}
.abt_sen {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 25px 40px;
}
.abt_data {
  width: 90%;
}
.abt_sen p {
    text-align: center;
    font-size: 17px;
    letter-spacing: 0.5px;
    word-spacing: 1px;
    line-height: 28px;
    width: 100%;
}
.abt_cnt_data .title h1 {
    font-size: 42px;
    text-transform: uppercase;
    font-family: 'Montserrat';
    font-weight: 300;
    letter-spacing: 1px;
    word-spacing: 0;
  }
  .contentinfra .title h3 {
    margin-bottom: 10px;
  }
  .bns_park_snap_bg{
    background-image: url(../images/snap-bg.webp);
  }

  .alin_im_left{
    object-position: left;

  }
  .mobile_visible{
   opacity: 0;
   visibility: hidden;
  }
